footer {
	padding            : 70px 0 45px;
	position           : relative;
	background-image   : url(../img/footer/bg-footer.png);
	background-size    : cover;
	background-position: center center;
	background-repeat  : no-repeat;

	ul {
		list-style: none;
		padding   : 0;
	}

	.col-lg-3,
	.col-lg-6 {
		z-index: 1;
	}

	.info-company {
		.logo {
			margin-bottom: 30px;
		}

		.list-item {
			.item {
				font-size    : 15px;
				color        : rgba(255, 255, 255, 0.6);
				margin-bottom: 5px;
			}
		}
	}

	.list-link-footer {
		display        : flex;
		justify-content: space-between;
		padding-right  : 60px;

		.block-link-footer {
			.title {
				color        : #FFFFFF;
				font-size    : 16px;
				font-weight  : 700;
				margin-bottom: 20px;
			}

			.list-item {
				.item {
					position    : relative;
					padding-left: 10px;

					>a {
						color      : rgba(255, 255, 255, 0.6);
						font-size  : 15px;
						font-weight: 400;
					}

					&::after {
						content      : '';
						position     : absolute;
						background   : #FFFFFF;
						width        : 5px;
						height       : 5px;
						top          : 50%;
						left         : 0;
						border-radius: 50%;
						transform    : translateY(-50%);
					}
				}
			}
		}
	}

	.follow-me {
		h5 {
			font-size    : 16px;
			color        : #FFFFFF;
			font-weight  : 400;
			margin-bottom: 15px;
		}

		.form-group-1 {
			position: relative;

			input {
				width         : 100%;
				font-size     : 16px;
				background    : transparent;
				border        : none;
				padding-bottom: 5px;
				border-bottom : 1px solid #FFFFFF;
				color         : rgba(255, 255, 255, 0.8);
			}

			button {
				display      : none;
				position     : absolute;
				right        : 0;
				top          : 0;
				background   : #ffffff;
				border       : none;
				width        : 50px;
				border-radius: 3px;
			}
		}

		.form-group-2 {
			margin-top: 30px;

			select {
				width     : 100%;
				background: rgb(75, 75, 75);
				border    : none;
				padding   : 10px;
				color     : rgba(255, 255, 255, 0.8);

				optgroup {
					font-size  : 16px;
					font-weight: 700;

					option {
						font-size  : 13px;
						font-weight: 400;
					}
				}
			}
		}
	}

	&:after {
		content       : '';
		position      : absolute;
		width         : 100%;
		height        : 100%;
		top           : 0;
		left          : 0;
		pointer-events: none;
		background    : rgba(99, 99, 99, 0.8);
	}

	@media (max-width: 1024.98px) {
		padding: 30px 0 45px;

		.info-company {
			padding      : 0 20px;
			margin-bottom: 10px;

			.logo {
				margin-bottom: 20px;
			}
		}

		.list-link-footer {
			flex-wrap    : wrap;
			padding      : 0 20px;
			margin-bottom: 15px;

			.block-link-footer {
				flex         : 0 0 50%;
				max-width    : 50%;
				margin-bottom: 15px;

				.title {
					margin-bottom: 15px;
				}
			}
		}

		.follow-me {
			padding: 0 20px;

			.form-group-1 {
				input {
					padding-right: 60px;
				}

				button {
					display: block;
				}
			}
		}
	}
}

.coppy-right {
	width     : 100%;
	padding   : 15px 0;
	background: rgba(75, 75, 75, 0.8);

	h5 {
		display    : inline-block;
		font-size  : 13px;
		color      : rgba(255, 255, 255, 0.6);
		font-weight: 300;

		&.right {
			float: right;
		}
	}

	a {
		color: rgba(255, 255, 255, 0.6);
	}

	@media (max-width: 1024.98px) {
		h5 {
			float        : unset;
			text-align   : center;
			display      : block;
			margin-bottom: 5px;

			&.right {
				float: unset;
			}
		}
	}
}