[hidden],
.d-none,
.hidden {
	display: none !important;
}

.ofc {
	width          : 100%;
	height         : 100%;
	object-fit     : cover;
	object-position: center;
	font-family    : 'object-fit: cover, object-position: center';
}

.vismis {
	margin       : 60px 0 80px;
	padding-right: 40px;

	.main-title {
		font-size    : 34px;
		color        : #2B2B2B;
		font-weight  : 700;
		margin-bottom: 40px;
	}

	.title-news {
		font-size    : 34px;
		color        : #2B2B2B;
		margin-bottom: 20px;
	}

	.time-news {
		display       : inline-block;
		font-size     : 13px;
		letter-spacing: 4px;
	}

	.ckFinder {
		p {
			font-size    : 18px;
			color        : #4D4D4D;
			margin-bottom: 15px;
		}

		figure {
			margin: 15px 0;
		}
	}

	.title-img {
		position    : relative;
		color       : #2B2B2B;
		font-size   : 24px;
		font-weight : 700;
		padding-left: 25px;
		margin      : 25px 0 20px;

		&:after {
			content         : '';
			position        : absolute;
			left            : 0;
			top             : 50%;
			transform       : translateY(-50%);
			width           : 20px;
			height          : 20px;
			background-image: url(../img/icons/sologan.png);
		}
	}

	@media (max-width: 1024.98px) {
		margin       : 30px 0;
		padding-right: 15px;

		.main-title {
			margin-bottom: 15px;
		}

		.title-news {
			margin-bottom: 0px;
		}

		.title-img {
			margin: 15px 0;
		}
	}

	@media (max-width: 767.98px) {
		margin: 15px 0;

		.title-news {
			font-size: 24px;
		}

		.main-title {
			font-size: 24px;
		}

		.ckFinder {
			p {
				font-size: 16px;
			}
		}

		.title-img {
			font-size: 16px;
		}
	}
}

.blur-up {
	filter    : blur(10px);
	transition: filter 1s;

	&.lazy-loaded {
		filter: blur(0);
	}
}

.btn {
	text-align   : center;
	background   : #FFFFFF;
	line-height  : 45px;
	width        : 220px;
	border       : 1px solid #707070;
	border-radius: 23px;
	transition   : .3s all ease-in-out;

	>a,
	span {
		cursor     : pointer;
		display    : block;
		font-size  : 16px;
		font-weight: 700;
		color      : #2B2B2B;
		width      : 100%;
		height     : 100%;
	}

	&.bg-blue {
		color     : #FFFFFF;
		background: #3E4095;
	}

	&:hover {
		background: #BC2E31;
		color     : #FFFFFF;
		border    : 1px solid transparent;

		>a,
		span {
			color: #FFFFFF;
		}

		&.bg-blue {
			color     : #FFFFFF;
			background: #3E4095;
			border    : 1px solid #707070;
		}
	}

	@media (max-width: 767.98px) {
		width: 190px;
	}
}

.btn-noClick {
	text-align   : center;
	background   : #FFFFFF;
	line-height  : 45px;
	width        : 220px;
	border       : 1px solid #707070;
	border-radius: 23px;
	transition   : .3s all ease-in-out;

	>a,
	span {
		cursor     : pointer;
		display    : block;
		font-size  : 16px;
		font-weight: 700;
		color      : #2B2B2B;
		width      : 100%;
		height     : 100%;
	}
}

.limit-line {
	overflow          : hidden;
	text-overflow     : ellipsis;
	display           : -webkit-box;
	-webkit-box-orient: vertical;
}

.form-report-request {
	margin       : 45px 0;
	padding-right: 40px;

	.title {
		h3 {
			font-size  : 34px;
			font-weight: 700;
			color      : #2B2B2B;
		}
	}

	.block-form {
		margin-top: 50px;

		form {
			display     : flex;
			flex-wrap   : wrap;
			margin-right: -25px;

			.form-group {
				flex         : 0 0 50%;
				max-width    : 50%;
				padding-right: 25px;

				label {
					cursor       : pointer;
					font-size    : 18px;
					color        : #2B2B2B;
					margin-bottom: 15px;
				}

				.form-input {
					position: relative;

					input {
						width      : 100%;
						line-height: 45px;
						color      : #707070;
						font-size  : 16px;
						border     : 1px solid #C1C1C1;
						padding    : 0 20px;
						font-style : italic;
					}

					.error {
						position : absolute;
						top      : 50%;
						transform: translateY(-50%);
						right    : 15px;
						color    : #BC2E31;
					}
				}
			}

			button {
				margin-top: 30px;
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin       : 30px 0;
		padding-right: 15px;

		.block-form {
			margin-top   : 20px;
			margin-bottom: 50px;

			&:last-child {
				margin-bottom: 0;
			}

			form {
				margin-right: 0;
				margin      : 0 -10px;

				.form-group {
					padding-right: 0;
					padding      : 0 10px;
				}

				button {
					margin-top : 20px;
					margin-left: 15px;
					width      : 170px;
					line-height: 35px;
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		margin: 15px 0;

		.title {
			h3 {
				font-size: 24px;
			}
		}

		.block-form {
			form {
				.form-group {
					flex         : 0 0 100%;
					max-width    : 100%;
					margin-bottom: 15px;
				}

				button {
					margin: 0 auto;
				}
			}
		}

	}
}

.aside-form-report-request {
	margin: 130px 0 45px;

	ul {
		list-style: none;
		padding   : 0;
	}

	.title-tracking {
		margin-bottom: 20px;

		h3 {
			font-size      : 24px;
			font-weight    : 400;
			text-decoration: underline;
			color          : #3E4095;
		}
	}

	.list-tracking {
		background: #F3F3F3;

		.item {
			color        : #2B2B2B;
			line-height  : 80px;
			padding-left : 15px;
			border-bottom: 1px dotted #707070;
			transition   : .2s all ease-in-out;

			&:hover {
				color     : #FFFFFF;
				background: #3E4095;
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin: 35px 0;
	}

	@media (max-width: 767.98px) {
		.title-tracking {
			margin-bottom: 15px;

			h3 {
				font-size: 16px;
			}
		}

		.list-tracking {
			.item {
				line-height: 50px;
				font-size  : 14px;
			}
		}
	}
}