body {
	&.disabled {
		overflow: hidden;
	}
}

main {
	padding-top: 120px;

	&.pt-0 {
		padding-top: 0;
	}

	@media (max-width: 1024.98px) {
		padding-top: 70px;

		&.pt-0 {
			padding-top: 70px;
		}
	}
}

.dichvu-page {
	#breadcrumb-wrapper {
		.breadcrumb {
			border-top: none;
		}
	}
}

::-webkit-scrollbar-track {
	box-shadow      : inset 0 0 6px rgba(255, 255, 255, 0);
	background-color: #fff;
}

::-webkit-scrollbar {
	width           : 10px;
	height          : 10px;
	background-color: #fff;

	@media (max-width: 1024.98px) {
		display: none;
	}
}

::-webkit-scrollbar-thumb {
	border-radius   : 2.5px;
	box-shadow      : inset 0 0 6px rgba(255, 255, 255, 0);
	background-color: #3E4095;
}

.social-list {
	display: flex;

	nav {
		margin-left: 10px;
		display    : flex;
		align-items: center;

		a {
			flex           : 0 0 30px;
			max-width      : 30px;
			display        : flex;
			justify-content: center;
		}
	}
}

.overlay {
	opacity       : 0;
	visibility    : hidden;
	pointer-events: none;

	background-color: rgba(0, 0, 0, .8);
	width           : 100%;
	height          : 100%;
	position        : fixed;
	top             : 0;
	left            : 0;
	z-index         : 70;
	transition      : .3s all ease-in-out;

	&.active {
		opacity       : 1;
		visibility    : visible;
		pointer-events: visible;
	}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border                 : 1px solid #C1C1C1;
	-webkit-text-fill-color: #3E4095;
	-webkit-box-shadow     : 0 0 0px 1000px #C1C1C1 inset;
	transition             : background-color 5000s ease-in-out 0s;
}