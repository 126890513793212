.home-about {
	background: #FFFFFF;
	padding   : 135px;

	ul {
	
	}

	.box-content {
		display: block;
		width  : 845px;
		margin : 0 auto;

		p {
			color     : #2B2B2B;
			text-align: center;
			font-size : 24px;
		}
	}

	@media (max-width: 1024.98px) {
		padding: 60px 0;

		.box-content {
			width: unset;
		}
	}

	@media (max-width: 767.98px) {
		.box-content {
			p {
				font-size: 16px;
			}
		}
	}
}