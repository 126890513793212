#doc-form {
	margin-top   : 45px;
	margin-bottom: 200px;
	.wrapper {
		display: flex;

	}
	ul {
		flex: 0 0 40%;
		max-width: 40%;
		list-style: none;
		padding   : 0;
		&.order-detail {
			margin-left: 10px;
			li {
				position: relative;
				font-weight: 500;
				padding-left: 10px;
				display: flex;
				p {
					flex: 0 0 50%;
					max-width: 50%;
				}
				&::before {
					content: "-";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
				}
			}
		}
	}
	.receiver-name {
		align-self: flex-end;
		flex: 0 0 60%;
		max-width: 60%;
	}

	.header-docForm {
		.thong-tin-cong-ty {
			color: $main-color-1;
		}
	}

	.content-docForm {
		margin-top: 30px;

		.title {
			text-align : center;
			font-weight: 700;

			.title-big {
				font-size: 40px;
			}

			.title-small {
				font-size : 20px;
				font-style: italic;
			}
		}

		.box-right {
			margin-left  : auto;
			padding-right: 15px;

			span {
				position    : relative;
				display     : inline-block;
				padding-left: 5px;
				font-weight : 700;

				&.title {
					padding-right: 50px;
					width        : 180px;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}
			}
		}

		.kinh-gui {
			padding-left: 170px;

			span {
				position    : relative;
				width       : 80%;
				font-size   : 20px;
				font-weight : 700;
				padding-left: 5px;
				margin-left : auto;

				&.title {
					width: 190px;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}
			}
		}

		.kinh-gui-small {
			display    : flex;
			flex-wrap  : wrap;
			margin-top : 50px;
			font-weight: 700;

			p {
				flex           : 0 0 10%;
				display        : inline-block;
				width          : 100px;
				font-style     : italic;
				text-decoration: underline;
			}

			span {
				flex       : 0 0 90%;
				margin-left: auto;
			}

			&.big-font {
				font-size: 20px;
			}
		}

		.text {
			margin: 15px 0;

			p {
				&.bold {
					font-weight: 700;
				}

				&.italic {
					font-style: italic;
				}

				&.text-right {
					text-align: right;
				}

				&.text-left {
					text-align: left;
				}

				&.underline {
					text-decoration: underline;
				}

				span.bold {
					font-weight: 700;
				}
			}
		}

		.thong-tin-don-hang {
			margin-top: 15px;

			.item {
				margin-bottom: 15px;

				p {
					&:nth-child(2) {
						font-style: italic;
						font-size : 14px;
					}

					&.bold {
						font-weight: 700;
					}

					&.text-center {
						text-align: center;
					}
				}
			}
		}

		.bang-thong-tin-don-hang {
			table {
				width : 100%;
				border: 1px solid #000000;

				thead {
					tr {
						th {
							border-right: 1px solid #000000;
							padding     : 5px;
							text-align  : center;

							p {
								&:last-child {
									font-size : 13px;
									font-style: italic;
								}
							}
						}
					}
				}

				tbody {
					tr {
						border-top: 1px solid #000000;

						td {
							padding     : 20px;
							text-align  : center;
							border-right: 1px solid #000000;

							p {
								&.bold {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}

		.mot-so-luu-y {
			margin-top : 30px;
			font-weight: 700;
			line-height: 30px;
		}

		.tong-chi-phi {
			width          : 100%;
			display        : flex;
			padding-right  : 15px;
			justify-content: space-between;
			margin-top     : 15px;
			margin-left    : 50%;
			font-weight    : 700;
		}

		.date {
			margin-top: 150px;
			text-align: right;
		}

		.nguoi-gui {
			span {
				i {
					font-size: 14px;
				}
			}

			p {
				&:first-child {
					font-weight: 700;
				}
			}

			.row {
				&:nth-child(2) {
					margin-top: 10px;
				}
			}
		}

		.giao-hang-theo {
			margin-top : 10px;
			font-weight: 700;

			h5 {
				text-align: center;
				padding   : 10px;
				border    : 1px solid #000000;
			}
		}

		.danh-sach-cac-chi-phi {
			margin-top: 40px;

			.ten-chi-phi {
				position    : relative;
				padding-left: 35px;

				&:after {
					content         : '';
					position        : absolute;
					left            : 0;
					top             : 0;
					width           : 26px;
					height          : 26px;
					background-image: url(../img/icons/checked.svg);
				}
			}

			ul {
				display        : flex;
				justify-content: space-between;

				&.tong-tien {
					width      : 50%;
					padding    : 0 15px;
					font-weight: 700;
					margin-left: auto;
					padding-top: 10px;
					border-top : 1px solid #000000;
				}
			}

			.row {
				margin-bottom: 10px;
			}
		}

		.bill {
			font-weight: 700;

			h3 {
				text-decoration: underline;
				margin-bottom  : 10px;
			}

			span {
				font-weight: 400;
			}

			.item {
				margin-bottom  : 5px;
				display        : flex;
				justify-content: space-between;
			}
		}

		.routing {

			h3 {
				font-weight    : 700;
				text-decoration: underline;
				margin-bottom  : 10px;
			}

			table {
				width : 100%;
				border: 1px solid #000000;

				thead {
					tr {
						th {
							font-size      : 13px;
							border-right   : 1px solid #000000;
							padding        : 5px;
							text-align     : center;
							text-decoration: underline;
						}
					}
				}
			}

			tbody {
				tr {
					border-top: 1px solid #000000;

					td {
						padding     : 5px;
						border-right: 1px solid #000000;
						text-align  : center;
					}
				}
			}
		}

		.thoi-gian-van-chuyen {
			margin-top: 30px;

			.item {
				display    : flex;
				font-weight: 700;

				p {
					position    : relative;
					width       : 200px;
					margin-right: 10px;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}
			}
		}

		.ten-muc-luc {
			margin-top: 10px;
			display   : flex;

			p {
				font-weight    : 700;
				text-decoration: underline;
				margin-right   : 10px;

				&:nth-child(2) {
					font-weight    : 400;
					text-decoration: unset;
				}
			}
		}

		.noi-dung-muc-luc {
			font-size   : 14px;
			margin-top  : 10px;
			padding-left: 30px;

			.muc-a {
				display  : flex;
				flex-wrap: wrap;

				p {
					position       : relative;
					flex           : 0 0 10%;
					display        : inline-block;
					width          : 100px;
					text-decoration: underline;
					font-weight    : 700;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}

				span {
					flex        : 0 0 90%;
					margin-left : auto;
					padding-left: 10px;
				}
			}

			.muc-c {
				.ten-cac-dieu-khoan {
					display  : flex;
					flex-wrap: wrap;

					.left {
						flex     : 0 0 30px;
						max-width: 30px;
					}

					.right {
						margin-right: auto;
					}

					&.mt-20 {
						margin-top: 20px;
					}
				}

				.noi-dung-chi-tiet-dieu-khoan {
					margin-top  : 20px;
					padding-left: 30px;

					.item {
						display      : flex;
						flex-wrap    : wrap;
						margin-bottom: 10px;

						.left {
							position : relative;
							flex     : 0 0 30%;
							max-width: 30%;

							&:after {
								content : '*';
								position: absolute;
								left    : -10px;
								top     : 0;
							}

							&:before {
								content : ':';
								position: absolute;
								right   : 0;
								top     : 0;
							}
						}

						.right {
							flex        : 0 0 70%;
							max-width   : 70%;
							padding-left: 10px;
						}
					}
				}
			}
		}

		.chu-ky {
			margin-top: 50px;

			.nguoi-ky {
				text-align: center;
			}
		}

		.info-loading {
			margin-top: 20px;

			.item {
				display      : flex;
				flex-wrap    : wrap;
				margin-bottom: 10px;

				p {
					position   : relative;
					flex       : 0 0 200px;
					width      : 200px;
					font-weight: 700;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}

				span {
					flex        : 0 0 700px;
					max-width   : 700px;
					padding-left: 20px;
				}
			}
		}

		.thong-tin-chi-tiet-loading {
			width : 70%;
			margin: 0 auto;


			.item {
				display      : flex;
				flex-wrap    : wrap;
				margin-bottom: 10px;

				p {
					position   : relative;
					flex       : 0 0 30%;
					width      : 30%;
					font-weight: 700;

					&:after {
						content : ':';
						position: absolute;
						right   : 0;
						top     : 0;
					}
				}

				span {
					flex        : 0 70%;
					max-width   : 70%;
					padding-left: 20px;
					margin-left : auto;
				}
			}
		}

		.bang-booking {
			width: 1200px;

			table {
				&.mt-10 {
					margin-top: 10px;
				}

				width: 1200px;
			}

			tr {

				td,
				th {
					padding: 10px;

					&.col-talbe-8 {
						width: 800.000004px;
					}

					&.col-table-6 {
						width: 600px;
					}

					&.col-table-4 {
						width: 399.9999996px;
					}

					&.col-table-3 {
						width: 300px;
					}

					&.col-table-2 {
						width: 200.000004px;
					}

					&.col-table-12 {
						width: 1200px;
					}

					&.no-border-top {
						border-top: none;
					}

					&.no-border-bottom {
						border-bottom: none;
					}
				}

				th {
					border-top   : 1px solid #000000;
					border-right : 1px solid #000000;
					border-left  : 1px solid #000000;
					border-bottom: 0;

					font-size  : 14px;
					font-style : italic;
					font-weight: 400;

					&.bold {
						font-weight: 700;
					}
				}

				td {
					border-bottom: 1px solid #000000;
					border-right : 1px solid #000000;
					border-left  : 1px solid #000000;
					border-top   : 0;

					&.border-top {
						border-top: 1px solid #000000;
					}

					&.bold {
						font-weight: 700;
						font-size  : 20px;
					}
				}
			}
		}

		.bang-hbl {
			table {
				width: 100%;

				tr {

					td,
					th {
						padding       : 10px;
						vertical-align: top;
					}

					td {
						border: 1px solid $main-color-1;

						&.text-center {
							text-align: center;
						}

						&.no-border-top {
							border-top: none;
						}

						&.no-border-bottom {
							border-bottom: none;
						}

						&.bold {
							font-weight: 700;
							font-size  : 20px;
						}

						h3 {
							font-size : 40px;
							color     : $main-color-1;
							text-align: center;
						}

						span {
							display  : block;
							font-size: 13px;
							color    : $main-color-1;
						}

						p {
							&.blue {
								color: $main-color-1;
							}
						}
					}

					th {
						border-right : 1px solid $main-color-1;
						border-left  : 1px solid $main-color-1;
						border-bottom: 1px solid $main-color-1;
						color        : $main-color-1;
						font-size    : 13px;

						&.no-border-bottom {
							border-bottom: none;
						}
					}
				}
			}

			.bottom-box {
				display        : flex;
				justify-content: space-between;
				flex-wrap      : wrap;
				padding-top    : 200px;
				padding-bottom : 40px;
				border-right   : 1px solid $main-color-1;
				border-left    : 1px solid $main-color-1;

				.text-1,
				.text-2,
				.ngay-thang-nam {
					flex      : 0 0 33.333333%;
					max-width : 33.333333%;
					text-align: center;
					padding   : 0 30px;
				}

				.ngay-thang-nam {
					padding-right: 40px;
					text-align   : right;
				}
			}


		}

		.bang-hawb {
			table {
				width: 100%;

				tr {

					td,
					th {
						padding       : 10px;
						vertical-align: top;

						&.vertical-center {
							vertical-align: middle;
						}

						&.vertical-bottom {
							vertical-align: bottom;
						}

						&.text-center {
							text-align: center;
						}
					}

					td {
						border: 1px solid $main-color-1;

						&.none-border-right {
							border-right: 1px solid transparent;
						}

						&.no-border-top {
							border-top: none;
						}

						&.no-border-bottom {
							border-bottom: none;
						}

						&.bold {
							font-weight: 700;
							font-size  : 20px;
						}

						&.w-50 {
							width: 50%;
						}

						h3 {
							font-size : 40px;
							color     : $main-color-1;
							text-align: center;

							&.text-left {
								text-align: left;
							}
						}

						h4 {
							font-size : 20px;
							color     : $main-color-1;
							text-align: center;

							&.text-left {
								text-align: left;
							}
						}

						span {
							display  : block;
							font-size: 13px;
							color    : $main-color-1;
						}

						p {
							&.blue {
								color: $main-color-1;
							}

							&.text-left {
								text-align: left;
							}

							&.text-center {
								text-align: center;
							}

							&.italic {
								font-style: italic;
							}

							&.border-bottom-dotted {
								border-bottom: 2px dotted #000000;
							}

							&.mt-10 {
								margin-top: 10px;
							}
						}
					}

					th {
						border-right : 1px solid $main-color-1;
						border-left  : 1px solid $main-color-1;
						border-bottom: 1px solid $main-color-1;
						color        : $main-color-1;
						font-size    : 13px;

						&.no-border-bottom {
							border-bottom: none;
						}
					}
				}
			}

			.ngay-thang-nam {
				padding-top   : 200px;
				padding-right : 40px;
				padding-bottom: 40px;
				text-align    : right;
				border-right  : 1px solid $main-color-1;
				border-left   : 1px solid $main-color-1;
			}
		}

	}
}