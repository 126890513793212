#back-to-top {
	cursor  : pointer;
	position: fixed;
	right   : 30px;
	bottom  : 70px;
	z-index : 100;

	opacity   : 0;
	visibility: hidden;
	transition: .5s all ease-in-out;

	&.active {
		opacity   : 1;
		visibility: visible;
	}

	@media (max-width: 767.98px) {
		display: none;
	}
}