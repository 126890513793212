.tienich-table {
	display: flex;
	margin-bottom: 30px;

	@media (max-width: 767.98px) {
		flex-wrap: wrap;
	}

	.table-img {
		flex: 0 0 340px;
		max-width: 340px;

		table {
			height: 100%;
		}

		@media (max-width: 767.98px) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 10px;
		}
	}

	.table-content {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;

		@media (max-width: 767.98px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.content {
		flex: 0 0 100%;
		max-width: 100%;
		display: block;
		overflow-x: auto;
	}

	table {
		width: 100%;

		thead {
			th {
				height: 65px;
				background: #3E4095;
				color: white;
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 400;
				text-align: center;
			}
		}

		tbody {
			td {
				font-size: 18px;
				text-align: center;

				@media (max-width: 1024.98px) {
					font-size: 14px;
				}
			}
		}

		th,
		td {
			border: 1px solid #C1C1C1;
			padding: 10px 15px;

			img {
				width: 100%;
			}
		}
	}
}

.tienich-chuyendoi {
	table {
		thead {
			th {
				text-align: center;
			}
		}

		tbody {
			th {
				text-align: center;
				font-weight: 400;
				color: #3E4095;
				text-decoration: underline;
			}
		}
	}
}

.tienich-tigia {
	table {
		thead {
			th {
				font-size: 18px;
				text-align: center;
			}
		}
	}
}

.tienich-tudien {
	.tab-container {
		margin-bottom: 60px;
	}

	.nav-characters {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;

		a {
			flex: 0 0 50px;
			max-width: 50px;
			border-radius: 5px;
			font-weight: 700;
			color: #3E4095;
			cursor: pointer;
			text-decoration: underline !important;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			transition: all .3s ease-out;

			&.active {
				color: #BC2E31;
				box-shadow: 0 3px 6px rgba(black, .15);
			}
		}
	}

	.tab-content {
		.item {
			font-size: 18px;
			margin-bottom: 25px;

			h4 {
				font-weight: 400;
				color: #3E4095;
			}

			p {
				color: #4D4D4D;
			}
		}
	}
}