header {
	position  : fixed;
	width     : 100%;
	top       : 0;
	left      : 0;
	background: rgba($color: #ffffff, $alpha: 1);
	z-index   : 100;
	height    : 120px;
	transition: .5s all ease-in-out;

	&.active {
		box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);

		@media (min-width: 1440px) {
			height    : 85px;
			background: rgba($color: #ffffff, $alpha: 1.0);

			.logo {
				margin-top: 0;
			}

			.top-header {
				height : 0;
				opacity: 0;

				.social-media-menu,
				.search {
					padding: 0;
					opacity: 0;
				}
			}

			.bottom-header {
				margin-top: 10px;
			}
		}
	}

	.container {
		padding   : 20px 10px 0 10px;
		position  : relative;
		transition: .3s all ease-in-out;
	}

	.col-no-rel {
		flex-basis: 0;
		flex-grow : 1;
		max-width : 100%;
	}

	.logo {
		margin-top: 28px;

		img {
			width: 100%;
		}

		@media (max-width: 1199.98px) {
			max-width: 180px;
			width    : 180px
		}

	}

	.toggle-menu-mobile {
		display: none;
	}

	.search-mobile {
		display: none;
	}

	.top-header {
		display    : flex;
		align-items: center;
		margin-left: auto;
		width      : 825px;
		overflow   : hidden;
		transition : .5s all ease-in-out;

		@media (max-width: 1199.98px) {
			max-width: 760px;
		}

		.social-media-menu {
			display      : flex;
			padding-right: 35px;
			margin-left  : auto;
			border-right : 1px solid #70707054;

			.item {
				margin-left: 20px;
			}
		}

		.search {
			position   : relative;
			max-width  : 280px;
			flex       : 0 0 280px;
			margin-left: 30px;

			input[type="text"] {
				font-size    : 12px;
				width        : 100%;
				height       : 25px;
				border-radius: 13px;
				border       : 1px solid #70707054;
				padding      : 5px 10px;
				background   : #FBFBFB;
				color        : #AFAFAF;
				font-style   : italic;
			}

			button {
				display        : flex;
				align-items    : center;
				justify-content: center;
				position       : absolute;
				right          : 10px;
				top            : 50%;
				transform      : translateY(-50%);
				border         : none;
				background     : none;
			}
		}
	}

	.bottom-header {
		width      : 825px;
		margin-left: auto;
		margin-top : 20px;

		@media (max-width: 1199.98px) {
			max-width: 760px;
		}

		.nav-list {
			display        : flex;
			justify-content: space-between;
		}

		.nav-item {
			position      : relative;
			display       : flex;
			align-items   : center;
			cursor        : pointer;
			padding-bottom: 28.5px;
			border-bottom : 2px solid transparent;

			font-size  : 16px;
			color      : #2B2B2B;
			font-weight: 700;
			transition : .3s all ease-in-out;

			>a {
				width      : 100%;
				font-size  : 16px;
				color      : #2B2B2B;
				font-weight: 700;
				transition : .3s all ease-in-out;
			}

			select {
				width      : 50px;
				cursor     : pointer;
				background : none;
				border     : none;
				font-size  : 16px;
				color      : #2B2B2B;
				font-weight: 400;
				appearance : none;
			}

			img {
				display   : none;
				position  : absolute;
				width     : 12px;
				height    : 7px;
				right     : 15px;
				top       : 30px;
				transition: .3s all ease-in-out;

				&.active {
					transform: rotate(180deg);
				}
			}

			&:last-child {
				&:after {
					content          : '';
					background-image : url(../img/icons/arrow-select.svg);
					position         : absolute;
					right            : 8px;
					top              : 8px;
					width            : 12px;
					height           : 7px;
					background-repeat: no-repeat;
					background-size  : cover;
					pointer-events   : none;
				}
			}

			&.active {
				color: #3E4095;

				>a {
					color: #3E4095;
				}
			}

			.sub-menu {
				position : absolute;
				width    : 285px;
				top      : 54px;
				left     : 50%;
				transform: translateX(-50%);
				z-index  : 5;

				background: #FFFFFF;
				box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);

				opacity   : 1;
				visibility: visible;

				.item {
					padding   : 15px;
					transition: .3s all ease-in-out;

					a {
						text-transform: capitalize;
						font-weight   : 400;
						font-size     : 16px;
						color         : #707070;
					}

					&:hover {
						background: #F3F3F3;

						>a {
							color: #3E4095;
						}

						>.sub-menu.children {
							opacity   : 1;
							visibility: visible;
						}
					}
				}

				&.children {
					opacity   : 0;
					visibility: hidden;
					left      : 150%;
					box-shadow: unset;
					transition: .2s all ease-in-out;
					background: #F3F3F3;
				}

				@media (min-width: 1025px) {
					opacity   : 0;
					visibility: hidden;
				}
			}

			@media (min-width: 1025px) {
				&:not(:last-child) {
					&:hover {
						color        : #3E4095;
						border-bottom: 2px solid #3E4095;

						>a {
							color: #3E4095;
						}

						>.sub-menu {
							opacity   : 1;
							visibility: visible;
							transition: .3s all ease-in-out;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		height    : 70px;
		background: #ffffff;

		.container {
			padding: 0;
			height : 100%;

			.row {
				height: 100%;
			}
		}

		&:hover {
			border-bottom: none;
		}

		.logo {
			position  : absolute;
			margin-top: 0;
			top       : 50%;
			left      : 50%;
			transform : translate(-50%, -50%);
			z-index   : 2;
		}

		.toggle-menu-mobile {
			display  : block;
			position : absolute;
			top      : 50%;
			transform: translateY(-50%);
			left     : 15px;

			.show-menu {
				&.active {
					display: none;
				}
			}

			.close {
				display: none;

				&.active {
					display: block;
				}
			}
		}

		.search-mobile {
			display  : block;
			position : absolute;
			top      : 50%;
			transform: translateY(-50%);
			right    : 15px;

			.search {
				&.active {
					display: none;
				}
			}

			.close {
				display: none;

				&.active {
					display: block;
				}
			}
		}

		.top-header {
			position   : absolute;
			top        : 70px;
			right      : -100%;
			margin-top : 0;
			margin-left: 0;
			width      : 100%;
			height     : 100px;
			background : #FFFFFF;
			transition : .3s all ease-in-out;
			border-top : 1px solid $main-color-1;

			&.active {
				right: 0;
			}

			.search {
				flex       : 0 0 100%;
				max-width  : 100%;
				margin-left: 0;
				padding    : 0 15px;

				input[type="text"] {
					// font-size    : 15px;
					height          : 50px;
					border-radius   : 0;
					border          : none;
					border-bottom   : 1px solid #dddddd;
					background      : none;
				}

				button {
					right: 20px;
				}
			}

			.social-media-menu {
				display: none;
			}
		}

		.bottom-header {
			position    : absolute;
			width       : 80%;
			height      : 100vh;
			top         : 70px;
			left        : -100%;
			margin-top  : 0;
			margin-left : 0;
			background  : #FFFFFF;
			transition  : .3s all ease-in-out;
			border-top  : 1px solid $main-color-1;
			border-right: 1px solid $main-color-1;
			overflow-y  : auto;

			&.active {
				left: 0;
			}

			.nav-list {
				display: block;

				.nav-item {
					padding       : 20px 0 20px 15px;
					border-bottom : 1px solid $main-color-1;
					flex-direction: column;
					align-items   : unset;

					.sub-menu {
						margin   : 25px 0;
						display  : none;
						width    : 96%;
						position : static;
						left     : 0;
						transform: translateX(0);

						.item {
							border-bottom: 1px solid $main-color-2;
						}

						&.children {
							display   : block;
							opacity   : 1;
							visibility: visible;
							margin    : 5px 0;
							background: none;

							.item {
								border-bottom: none !important;
							}
						}
					}

					img {
						display: block;
					}

					select {
						width: unset;
					}

					&:last-child {
						&:after {
							right: 15px;
							top  : 28px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.bottom-header {
			width       : 100%;
			border-right: unset;

			.nav-list {
				.nav-item {
					padding: 10px 0 10px 15px;

					>a {
						font-size: 13px;
					}

					img {
						top: 15px;
					}

					&:last-child {
						&:after {
							top: 15px;
						}
					}

					.sub-menu {
						.item {
							>a {
								font-size: 13px;
							}
						}
					}
				}

				select {
					font-size: 13px;
				}
			}
		}
	}
}