.aside-same-news {
	margin: 60px 0 20px;

	.title {
		position  : relative;
		height    : 65px;
		background: #F3F3F3;
		box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

		h3 {
			font-size    : 24px;
			color        : #3E4095;
			font-weight  : 400;
			line-height  : 65px;
			padding-left : 12px;
			border-bottom: 1px solid #3E4095;
		}
	}

	.list-same-news {
		background: #F3F3F3;

		.item {
			padding      : 20px 12px 20px;
			border-bottom: 1px solid #FFFFFF;

			figure {
				display: flex;

				.box-img {
					position    : relative;
					min-width   : 148px;
					height      : 120px;
					overflow    : hidden;
					margin-right: 25px;

					img {
						transition: .5s all ease-in-out;
					}

					.icon {
						background-image : url(../img/icons/service-white.svg);
						width            : 30px;
						height           : 30px;
						background-repeat: no-repeat;

						position      : absolute;
						top           : 50%;
						left          : -100%;
						transform     : translate(-50%, -100%);
						z-index       : 1;
						opacity       : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;
					}

					&:after {
						content       : '';
						position      : absolute;
						opacity       : 0;
						width         : 100%;
						height        : 100%;
						top           : 0;
						left          : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;

						background: rgb(62, 64, 149);
						background: -moz-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						background: -webkit-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						background: linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E4095", endColorstr="#3E4095", GradientType=1);
					}
				}

				figcaption {
					align-self: center;
					color     : #2B2B2B;

					.title-news {
						font-size         : 14px;
						max-height        : 63px;
						-webkit-line-clamp: 3;
						margin-bottom     : 10px;
					}

					.time {
						font-size     : 13px;
						letter-spacing: 4px;
					}
				}
			}

			&:hover {
				figure {
					.box-img {
						img {
							transform: scale(1.1);
						}

						.icon {
							left          : 50%;
							transform     : translate(-50%, -50%);
							opacity       : 1;
							pointer-events: visible;
						}

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin-top: 45px;

		.title {
			height       : unset;
			background   : transparent;
			box-shadow   : none;
			margin-bottom: 5px;

			h3 {
				text-decoration: underline;
				line-height    : unset;
				padding-left   : 0;
				border-bottom  : none;
			}
		}

		.list-same-news {
			background: #FFFFFF;

			.item {
				padding      : 15px 0 10px;
				border-bottom: 1px solid #F3F3F3;

				figure {
					.box-img {
						min-width   : 250px;
						height      : 170px;
						margin-right: 20px;
					}

					figcaption {
						align-self: center;
						color     : #2B2B2B;

						.title-news {
							font-size         : 14px;
							max-height        : 63px;
							-webkit-line-clamp: 3;
						}

						.time {
							font-size     : 13px;
							letter-spacing: 4px;
						}
					}
				}

				&:hover {
					figure {
						.box-img {
							img {
								transform: scale(1.1);
							}

							.icon {
								left          : 50%;
								transform     : translate(-50%, -50%);
								opacity       : 1;
								pointer-events: visible;
							}

							&:after {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.list-same-news {
			.item {
				figure {
					display: block;

					.box-img {
						min-width   : unset;
						margin-right: 0;
						height      : 280px;
					}

					figcaption {
						margin-top: 15px;

						.title-news {
							font-size         : 14px;
							max-height        : 42px;
							-webkit-line-clamp: 2;
							margin-bottom     : 5px;
						}

						.time {
							font-size     : 13px;
							letter-spacing: 4px;
						}
					}
				}

				&:hover {
					figure {
						.box-img {
							img {
								transform: scale(1.1);
							}

							.icon {
								left          : 50%;
								transform     : translate(-50%, -50%);
								opacity       : 1;
								pointer-events: visible;
							}

							&:after {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}