@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=3.5.94");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=3.5.94") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=3.5.94") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=3.5.94") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=3.5.94") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=3.5.94#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab:before {
  font-family: "Font Awesome 5 Brands"; }

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400; }

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa:before,
.fas:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900; }

.fa:before,
.fas:before,
.far:before,
.fal:before,
.fab:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

@font-face {
  font-family: "Linearicons-Free";
  src: url("../fonts/Linearicons-Free.eot?w118d");
  src: url("../fonts/Linearicons-Free.eot?#iefixw118d") format("embedded-opentype"), url("../fonts/Linearicons-Free.woff2?w118d") format("woff2"), url("../fonts/Linearicons-Free.woff?w118d") format("woff"), url("../fonts/Linearicons-Free.ttf?w118d") format("truetype"), url("../fonts/Linearicons-Free.svg?w118d#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal; }

.lnr:before {
  font-family: "Linearicons-Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #1c1c1c;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

select:disabled {
  background: #ebebe4 !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

/* @media (min-width: 768px) {
	.container {
		max-width: 740px;
	}
} */
@media (min-width: 1025px) {
  .container {
    max-width: 990px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

@media (min-width: 1360px) {
  .container {
    max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.form-row {
  display: flex;
  flex-wrap: wrap; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1025px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

body.disabled {
  overflow: hidden; }

main {
  padding-top: 120px; }
  main.pt-0 {
    padding-top: 0; }
  @media (max-width: 1024.98px) {
    main {
      padding-top: 70px; }
      main.pt-0 {
        padding-top: 70px; } }

.dichvu-page #breadcrumb-wrapper .breadcrumb {
  border-top: none; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #fff; }

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #fff; }
  @media (max-width: 1024.98px) {
    ::-webkit-scrollbar {
      display: none; } }

::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #3E4095; }

.social-list {
  display: flex; }
  .social-list nav {
    margin-left: 10px;
    display: flex;
    align-items: center; }
    .social-list nav a {
      flex: 0 0 30px;
      max-width: 30px;
      display: flex;
      justify-content: center; }

.overlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  transition: .3s all ease-in-out; }
  .overlay.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #C1C1C1;
  -webkit-text-fill-color: #3E4095;
  -webkit-box-shadow: 0 0 0px 1000px #C1C1C1 inset;
  transition: background-color 5000s ease-in-out 0s; }

[hidden],
.d-none,
.hidden {
  display: none !important; }

.ofc {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover, object-position: center'; }

.vismis {
  margin: 60px 0 80px;
  padding-right: 40px; }
  .vismis .main-title {
    font-size: 34px;
    color: #2B2B2B;
    font-weight: 700;
    margin-bottom: 40px; }
  .vismis .title-news {
    font-size: 34px;
    color: #2B2B2B;
    margin-bottom: 20px; }
  .vismis .time-news {
    display: inline-block;
    font-size: 13px;
    letter-spacing: 4px; }
  .vismis .ckFinder p {
    font-size: 18px;
    color: #4D4D4D;
    margin-bottom: 15px; }
  .vismis .ckFinder figure {
    margin: 15px 0; }
  .vismis .title-img {
    position: relative;
    color: #2B2B2B;
    font-size: 24px;
    font-weight: 700;
    padding-left: 25px;
    margin: 25px 0 20px; }
    .vismis .title-img:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-image: url(../img/icons/sologan.png); }
  @media (max-width: 1024.98px) {
    .vismis {
      margin: 30px 0;
      padding-right: 15px; }
      .vismis .main-title {
        margin-bottom: 15px; }
      .vismis .title-news {
        margin-bottom: 0px; }
      .vismis .title-img {
        margin: 15px 0; } }
  @media (max-width: 767.98px) {
    .vismis {
      margin: 15px 0; }
      .vismis .title-news {
        font-size: 24px; }
      .vismis .main-title {
        font-size: 24px; }
      .vismis .ckFinder p {
        font-size: 16px; }
      .vismis .title-img {
        font-size: 16px; } }

.blur-up {
  filter: blur(10px);
  transition: filter 1s; }
  .blur-up.lazy-loaded {
    filter: blur(0); }

.btn {
  text-align: center;
  background: #FFFFFF;
  line-height: 45px;
  width: 220px;
  border: 1px solid #707070;
  border-radius: 23px;
  transition: .3s all ease-in-out; }
  .btn > a,
  .btn span {
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #2B2B2B;
    width: 100%;
    height: 100%; }
  .btn.bg-blue {
    color: #FFFFFF;
    background: #3E4095; }
  .btn:hover {
    background: #BC2E31;
    color: #FFFFFF;
    border: 1px solid transparent; }
    .btn:hover > a,
    .btn:hover span {
      color: #FFFFFF; }
    .btn:hover.bg-blue {
      color: #FFFFFF;
      background: #3E4095;
      border: 1px solid #707070; }
  @media (max-width: 767.98px) {
    .btn {
      width: 190px; } }

.btn-noClick {
  text-align: center;
  background: #FFFFFF;
  line-height: 45px;
  width: 220px;
  border: 1px solid #707070;
  border-radius: 23px;
  transition: .3s all ease-in-out; }
  .btn-noClick > a,
  .btn-noClick span {
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #2B2B2B;
    width: 100%;
    height: 100%; }

.limit-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.form-report-request {
  margin: 45px 0;
  padding-right: 40px; }
  .form-report-request .title h3 {
    font-size: 34px;
    font-weight: 700;
    color: #2B2B2B; }
  .form-report-request .block-form {
    margin-top: 50px; }
    .form-report-request .block-form form {
      display: flex;
      flex-wrap: wrap;
      margin-right: -25px; }
      .form-report-request .block-form form .form-group {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 25px; }
        .form-report-request .block-form form .form-group label {
          cursor: pointer;
          font-size: 18px;
          color: #2B2B2B;
          margin-bottom: 15px; }
        .form-report-request .block-form form .form-group .form-input {
          position: relative; }
          .form-report-request .block-form form .form-group .form-input input {
            width: 100%;
            line-height: 45px;
            color: #707070;
            font-size: 16px;
            border: 1px solid #C1C1C1;
            padding: 0 20px;
            font-style: italic; }
          .form-report-request .block-form form .form-group .form-input .error {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            color: #BC2E31; }
      .form-report-request .block-form form button {
        margin-top: 30px; }
  @media (max-width: 1024.98px) {
    .form-report-request {
      margin: 30px 0;
      padding-right: 15px; }
      .form-report-request .block-form {
        margin-top: 20px;
        margin-bottom: 50px; }
        .form-report-request .block-form:last-child {
          margin-bottom: 0; }
        .form-report-request .block-form form {
          margin-right: 0;
          margin: 0 -10px; }
          .form-report-request .block-form form .form-group {
            padding-right: 0;
            padding: 0 10px; }
          .form-report-request .block-form form button {
            margin-top: 20px;
            margin-left: 15px;
            width: 170px;
            line-height: 35px; } }
  @media (max-width: 767.98px) {
    .form-report-request {
      margin: 15px 0; }
      .form-report-request .title h3 {
        font-size: 24px; }
      .form-report-request .block-form form .form-group {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px; }
      .form-report-request .block-form form button {
        margin: 0 auto; } }

.aside-form-report-request {
  margin: 130px 0 45px; }
  .aside-form-report-request ul {
    list-style: none;
    padding: 0; }
  .aside-form-report-request .title-tracking {
    margin-bottom: 20px; }
    .aside-form-report-request .title-tracking h3 {
      font-size: 24px;
      font-weight: 400;
      text-decoration: underline;
      color: #3E4095; }
  .aside-form-report-request .list-tracking {
    background: #F3F3F3; }
    .aside-form-report-request .list-tracking .item {
      color: #2B2B2B;
      line-height: 80px;
      padding-left: 15px;
      border-bottom: 1px dotted #707070;
      transition: .2s all ease-in-out; }
      .aside-form-report-request .list-tracking .item:hover {
        color: #FFFFFF;
        background: #3E4095; }
  @media (max-width: 1024.98px) {
    .aside-form-report-request {
      margin: 35px 0; } }
  @media (max-width: 767.98px) {
    .aside-form-report-request .title-tracking {
      margin-bottom: 15px; }
      .aside-form-report-request .title-tracking h3 {
        font-size: 16px; }
    .aside-form-report-request .list-tracking .item {
      line-height: 50px;
      font-size: 14px; } }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  height: 120px;
  transition: .5s all ease-in-out; }
  header.active {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    @media (min-width: 1440px) {
      header.active {
        height: 85px;
        background: white; }
        header.active .logo {
          margin-top: 0; }
        header.active .top-header {
          height: 0;
          opacity: 0; }
          header.active .top-header .social-media-menu,
          header.active .top-header .search {
            padding: 0;
            opacity: 0; }
        header.active .bottom-header {
          margin-top: 10px; } }
  header .container {
    padding: 20px 10px 0 10px;
    position: relative;
    transition: .3s all ease-in-out; }
  header .col-no-rel {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  header .logo {
    margin-top: 28px; }
    header .logo img {
      width: 100%; }
    @media (max-width: 1199.98px) {
      header .logo {
        max-width: 180px;
        width: 180px; } }
  header .toggle-menu-mobile {
    display: none; }
  header .search-mobile {
    display: none; }
  header .top-header {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 825px;
    overflow: hidden;
    transition: .5s all ease-in-out; }
    @media (max-width: 1199.98px) {
      header .top-header {
        max-width: 760px; } }
    header .top-header .social-media-menu {
      display: flex;
      padding-right: 35px;
      margin-left: auto;
      border-right: 1px solid #70707054; }
      header .top-header .social-media-menu .item {
        margin-left: 20px; }
    header .top-header .search {
      position: relative;
      max-width: 280px;
      flex: 0 0 280px;
      margin-left: 30px; }
      header .top-header .search input[type="text"] {
        font-size: 12px;
        width: 100%;
        height: 25px;
        border-radius: 13px;
        border: 1px solid #70707054;
        padding: 5px 10px;
        background: #FBFBFB;
        color: #AFAFAF;
        font-style: italic; }
      header .top-header .search button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background: none; }
  header .bottom-header {
    width: 825px;
    margin-left: auto;
    margin-top: 20px; }
    @media (max-width: 1199.98px) {
      header .bottom-header {
        max-width: 760px; } }
    header .bottom-header .nav-list {
      display: flex;
      justify-content: space-between; }
    header .bottom-header .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 28.5px;
      border-bottom: 2px solid transparent;
      font-size: 16px;
      color: #2B2B2B;
      font-weight: 700;
      transition: .3s all ease-in-out; }
      header .bottom-header .nav-item > a {
        width: 100%;
        font-size: 16px;
        color: #2B2B2B;
        font-weight: 700;
        transition: .3s all ease-in-out; }
      header .bottom-header .nav-item select {
        width: 50px;
        cursor: pointer;
        background: none;
        border: none;
        font-size: 16px;
        color: #2B2B2B;
        font-weight: 400;
        appearance: none; }
      header .bottom-header .nav-item img {
        display: none;
        position: absolute;
        width: 12px;
        height: 7px;
        right: 15px;
        top: 30px;
        transition: .3s all ease-in-out; }
        header .bottom-header .nav-item img.active {
          transform: rotate(180deg); }
      header .bottom-header .nav-item:last-child:after {
        content: '';
        background-image: url(../img/icons/arrow-select.svg);
        position: absolute;
        right: 8px;
        top: 8px;
        width: 12px;
        height: 7px;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none; }
      header .bottom-header .nav-item.active {
        color: #3E4095; }
        header .bottom-header .nav-item.active > a {
          color: #3E4095; }
      header .bottom-header .nav-item .sub-menu {
        position: absolute;
        width: 285px;
        top: 54px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        visibility: visible; }
        header .bottom-header .nav-item .sub-menu .item {
          padding: 15px;
          transition: .3s all ease-in-out; }
          header .bottom-header .nav-item .sub-menu .item a {
            text-transform: capitalize;
            font-weight: 400;
            font-size: 16px;
            color: #707070; }
          header .bottom-header .nav-item .sub-menu .item:hover {
            background: #F3F3F3; }
            header .bottom-header .nav-item .sub-menu .item:hover > a {
              color: #3E4095; }
            header .bottom-header .nav-item .sub-menu .item:hover > .sub-menu.children {
              opacity: 1;
              visibility: visible; }
        header .bottom-header .nav-item .sub-menu.children {
          opacity: 0;
          visibility: hidden;
          left: 150%;
          box-shadow: unset;
          transition: .2s all ease-in-out;
          background: #F3F3F3; }
        @media (min-width: 1025px) {
          header .bottom-header .nav-item .sub-menu {
            opacity: 0;
            visibility: hidden; } }
      @media (min-width: 1025px) {
        header .bottom-header .nav-item:not(:last-child):hover {
          color: #3E4095;
          border-bottom: 2px solid #3E4095; }
          header .bottom-header .nav-item:not(:last-child):hover > a {
            color: #3E4095; }
          header .bottom-header .nav-item:not(:last-child):hover > .sub-menu {
            opacity: 1;
            visibility: visible;
            transition: .3s all ease-in-out; } }
  @media (max-width: 1024.98px) {
    header {
      height: 70px;
      background: #ffffff; }
      header .container {
        padding: 0;
        height: 100%; }
        header .container .row {
          height: 100%; }
      header:hover {
        border-bottom: none; }
      header .logo {
        position: absolute;
        margin-top: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2; }
      header .toggle-menu-mobile {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px; }
        header .toggle-menu-mobile .show-menu.active {
          display: none; }
        header .toggle-menu-mobile .close {
          display: none; }
          header .toggle-menu-mobile .close.active {
            display: block; }
      header .search-mobile {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px; }
        header .search-mobile .search.active {
          display: none; }
        header .search-mobile .close {
          display: none; }
          header .search-mobile .close.active {
            display: block; }
      header .top-header {
        position: absolute;
        top: 70px;
        right: -100%;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        transition: .3s all ease-in-out;
        border-top: 1px solid #3E4095; }
        header .top-header.active {
          right: 0; }
        header .top-header .search {
          flex: 0 0 100%;
          max-width: 100%;
          margin-left: 0;
          padding: 0 15px; }
          header .top-header .search input[type="text"] {
            height: 50px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #dddddd;
            background: none; }
          header .top-header .search button {
            right: 20px; }
        header .top-header .social-media-menu {
          display: none; }
      header .bottom-header {
        position: absolute;
        width: 80%;
        height: 100vh;
        top: 70px;
        left: -100%;
        margin-top: 0;
        margin-left: 0;
        background: #FFFFFF;
        transition: .3s all ease-in-out;
        border-top: 1px solid #3E4095;
        border-right: 1px solid #3E4095;
        overflow-y: auto; }
        header .bottom-header.active {
          left: 0; }
        header .bottom-header .nav-list {
          display: block; }
          header .bottom-header .nav-list .nav-item {
            padding: 20px 0 20px 15px;
            border-bottom: 1px solid #3E4095;
            flex-direction: column;
            align-items: unset; }
            header .bottom-header .nav-list .nav-item .sub-menu {
              margin: 25px 0;
              display: none;
              width: 96%;
              position: static;
              left: 0;
              transform: translateX(0); }
              header .bottom-header .nav-list .nav-item .sub-menu .item {
                border-bottom: 1px solid #BC2E31; }
              header .bottom-header .nav-list .nav-item .sub-menu.children {
                display: block;
                opacity: 1;
                visibility: visible;
                margin: 5px 0;
                background: none; }
                header .bottom-header .nav-list .nav-item .sub-menu.children .item {
                  border-bottom: none !important; }
            header .bottom-header .nav-list .nav-item img {
              display: block; }
            header .bottom-header .nav-list .nav-item select {
              width: unset; }
            header .bottom-header .nav-list .nav-item:last-child:after {
              right: 15px;
              top: 28px; } }
  @media (max-width: 767.98px) {
    header .bottom-header {
      width: 100%;
      border-right: unset; }
      header .bottom-header .nav-list .nav-item {
        padding: 10px 0 10px 15px; }
        header .bottom-header .nav-list .nav-item > a {
          font-size: 13px; }
        header .bottom-header .nav-list .nav-item img {
          top: 15px; }
        header .bottom-header .nav-list .nav-item:last-child:after {
          top: 15px; }
        header .bottom-header .nav-list .nav-item .sub-menu .item > a {
          font-size: 13px; }
      header .bottom-header .nav-list select {
        font-size: 13px; } }

footer {
  padding: 70px 0 45px;
  position: relative;
  background-image: url(../img/footer/bg-footer.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  footer ul {
    list-style: none;
    padding: 0; }
  footer .col-lg-3,
  footer .col-lg-6 {
    z-index: 1; }
  footer .info-company .logo {
    margin-bottom: 30px; }
  footer .info-company .list-item .item {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 5px; }
  footer .list-link-footer {
    display: flex;
    justify-content: space-between;
    padding-right: 60px; }
    footer .list-link-footer .block-link-footer .title {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px; }
    footer .list-link-footer .block-link-footer .list-item .item {
      position: relative;
      padding-left: 10px; }
      footer .list-link-footer .block-link-footer .list-item .item > a {
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;
        font-weight: 400; }
      footer .list-link-footer .block-link-footer .list-item .item::after {
        content: '';
        position: absolute;
        background: #FFFFFF;
        width: 5px;
        height: 5px;
        top: 50%;
        left: 0;
        border-radius: 50%;
        transform: translateY(-50%); }
  footer .follow-me h5 {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 400;
    margin-bottom: 15px; }
  footer .follow-me .form-group-1 {
    position: relative; }
    footer .follow-me .form-group-1 input {
      width: 100%;
      font-size: 16px;
      background: transparent;
      border: none;
      padding-bottom: 5px;
      border-bottom: 1px solid #FFFFFF;
      color: rgba(255, 255, 255, 0.8); }
    footer .follow-me .form-group-1 button {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #ffffff;
      border: none;
      width: 50px;
      border-radius: 3px; }
  footer .follow-me .form-group-2 {
    margin-top: 30px; }
    footer .follow-me .form-group-2 select {
      width: 100%;
      background: #4b4b4b;
      border: none;
      padding: 10px;
      color: rgba(255, 255, 255, 0.8); }
      footer .follow-me .form-group-2 select optgroup {
        font-size: 16px;
        font-weight: 700; }
        footer .follow-me .form-group-2 select optgroup option {
          font-size: 13px;
          font-weight: 400; }
  footer:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: rgba(99, 99, 99, 0.8); }
  @media (max-width: 1024.98px) {
    footer {
      padding: 30px 0 45px; }
      footer .info-company {
        padding: 0 20px;
        margin-bottom: 10px; }
        footer .info-company .logo {
          margin-bottom: 20px; }
      footer .list-link-footer {
        flex-wrap: wrap;
        padding: 0 20px;
        margin-bottom: 15px; }
        footer .list-link-footer .block-link-footer {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 15px; }
          footer .list-link-footer .block-link-footer .title {
            margin-bottom: 15px; }
      footer .follow-me {
        padding: 0 20px; }
        footer .follow-me .form-group-1 input {
          padding-right: 60px; }
        footer .follow-me .form-group-1 button {
          display: block; } }

.coppy-right {
  width: 100%;
  padding: 15px 0;
  background: rgba(75, 75, 75, 0.8); }
  .coppy-right h5 {
    display: inline-block;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300; }
    .coppy-right h5.right {
      float: right; }
  .coppy-right a {
    color: rgba(255, 255, 255, 0.6); }
  @media (max-width: 1024.98px) {
    .coppy-right h5 {
      float: unset;
      text-align: center;
      display: block;
      margin-bottom: 5px; }
      .coppy-right h5.right {
        float: unset; } }

#breadcrumb-wrapper .breadcrumb {
  list-style: none;
  display: flex;
  padding: 0;
  border-top: 1px dotted #707070;
  border-bottom: 1px dotted #707070; }
  #breadcrumb-wrapper .breadcrumb li a {
    color: #707070;
    font-size: 16px;
    display: inline-block;
    padding: 20px 0; }
  #breadcrumb-wrapper .breadcrumb li.active a {
    color: #3E4095; }
  #breadcrumb-wrapper .breadcrumb li + li:before {
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F13E";
    font-size: 10px;
    margin: 0 12px;
    color: #1C1C1C; }

@media (max-width: 767.98px) {
  #breadcrumb-wrapper .breadcrumb li a {
    font-size: 12px;
    padding: 10px 0; } }

.pagination {
  background: #FFFFFF;
  display: flex;
  justify-content: flex-end;
  padding: 30px 0; }
  @media (max-width: 1024.98px) {
    .pagination {
      justify-content: center; } }
  .pagination li {
    margin: 0 10px; }
    @media (max-width: 1024.98px) {
      .pagination li {
        margin: 0 5px; } }
    .pagination li a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      color: #000000;
      border-radius: 5px;
      font-weight: 400;
      font-size: 12px;
      background: #dddddd;
      border: 1px solid #FFFFFF;
      transition: all 0.2s cubic-bezier(0.2, 0.5, 0.5, 0.8); }
    .pagination li:hover a {
      color: #FFFFFF;
      background: #3E4095; }
    .pagination li:last-child {
      margin: 0 0 0 10px; }
    .pagination li.active a {
      color: #FFFFFF;
      background: #3E4095;
      text-decoration: underline; }
    .pagination li.pagination-dot a {
      border: 0; }

.lnr-chevron-left::before {
  content: '\e879'; }

.lnr-chevron-right::before {
  content: '\e87a'; }

.see-more-pc {
  position: fixed;
  right: 0;
  top: 30vh;
  z-index: 100;
  width: 62px;
  height: 56px;
  background-color: #bc2e31;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  transition: .3s all ease-in-out;
  cursor: pointer; }
  .see-more-pc img {
    padding: 10px;
    animation: rotate_square linear 3s infinite; }

@keyframes rotate_square {
  10% {
    transform: rotate(72deg);
    animation-delay: 0.3s; }
  20% {
    transform: rotate(144deg);
    animation-delay: 0.3s; }
  30% {
    transform: rotate(216deg);
    animation-delay: 0.3s; }
  40% {
    transform: rotate(288deg);
    animation-delay: 0.3s; }
  50% {
    transform: rotate(360deg);
    animation-delay: 0.3s; }
  60% {
    transform: rotate(432deg);
    animation-delay: 0.3s; }
  70% {
    transform: rotate(504deg);
    animation-delay: 0.3s; }
  80% {
    transform: rotate(576deg);
    animation-delay: 0.3s; }
  90% {
    transform: rotate(648deg);
    animation-delay: 0.3s; }
  100% {
    transform: rotate(720deg);
    animation-delay: 0.3s; } }
  @media (max-width: 480px) {
    .see-more-pc {
      display: none; } }

.see-more-show {
  width: 70px;
  right: 105px;
  transition: .55s; }

.fix-item {
  width: 105px;
  position: fixed;
  right: 0;
  top: 30vh;
  padding: 20px 10px 20px 15px;
  z-index: 99;
  display: none;
  background: #FFFFFF;
  border-radius: 0 0 0 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .fix-item .see-more-mb {
    display: none; }
  .fix-item .par .item {
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    background: #EFEFEF; }
    .fix-item .par .item:last-child {
      margin-bottom: 0; }
    .fix-item .par .item p {
      color: #2B2B2B;
      padding-top: 10px; }
    .fix-item .par .item .img {
      position: relative;
      left: 0;
      right: 0;
      margin: 0 auto; }
    .fix-item .par .item:hover {
      background-color: #BC2E31; }
      .fix-item .par .item:hover .img svg path {
        fill: #FFFFFF; }
      .fix-item .par .item:hover p {
        color: #FFFFFF; }

@keyframes animate_small {
  20% {
    opacity: 0.9;
    animation-delay: 0.2s; }
  40% {
    opacity: 0.7;
    animation-delay: 0.2s; }
  60% {
    opacity: 0.5;
    animation-delay: 0.2s; }
  80% {
    opacity: 0.3;
    animation-delay: 0.2s; }
  100% {
    opacity: 0.1;
    animation-delay: 0.2s; } }
  @media (max-width: 480px) {
    .fix-item {
      bottom: 0;
      left: 0;
      width: 100%;
      top: unset;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;
      border: 1px solid #3E4095;
      display: block;
      padding: 5px;
      background: #3E4095; }
      .fix-item .see-more-mb {
        display: block;
        text-align: center;
        font-size: 17px;
        color: #fff;
        padding: 5px 0;
        animation: animate_small linear 1s infinite; }
      .fix-item .par {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .fix-item .par .item {
          display: none;
          -ms-flex: 0 0 48%;
          flex: 0 0 48%;
          max-width: 48%;
          margin: 10px 1% 5px 1%;
          padding: 10px; }
          .fix-item .par .item:last-child {
            margin-bottom: 5px; } }

#back-to-top {
  cursor: pointer;
  position: fixed;
  right: 30px;
  bottom: 70px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: .5s all ease-in-out; }
  #back-to-top.active {
    opacity: 1;
    visibility: visible; }
  @media (max-width: 767.98px) {
    #back-to-top {
      display: none; } }

aside {
  margin: 60px 0 80px; }
  aside .block-service .title {
    position: relative;
    height: 65px;
    background: #F3F3F3;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    aside .block-service .title h3 {
      font-size: 24px;
      color: #3E4095;
      font-weight: 400;
      line-height: 65px;
      padding-left: 12px; }
  aside .block-service .list-item-service {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    aside .block-service .list-item-service figure {
      position: relative;
      height: 80px;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted #707070;
      transition: .3s all ease-in-out; }
      aside .block-service .list-item-service figure .icon {
        width: 70px;
        text-align: center; }
      aside .block-service .list-item-service figure figcaption {
        margin-left: 20px; }
        aside .block-service .list-item-service figure figcaption span {
          font-size: 18px;
          color: #2B2B2B; }
      aside .block-service .list-item-service figure:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 70px;
        transform: translateY(-50%);
        width: 1px;
        height: 60px;
        border: .5px dotted #707070; }
      aside .block-service .list-item-service figure:hover {
        background: #3E4095; }
        aside .block-service .list-item-service figure:hover .icon svg path {
          fill: #FFFFFF; }
        aside .block-service .list-item-service figure:hover figcaption span {
          color: #FFFFFF; }
        aside .block-service .list-item-service figure:hover:after {
          border: .5px dotted #FFFFFF; }
  aside .block-form-tu-van {
    margin-top: 80px; }
    aside .block-form-tu-van .title {
      position: relative;
      height: 65px;
      background: #BC2E31;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
      aside .block-form-tu-van .title h3 {
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 65px;
        padding-left: 12px; }
    aside .block-form-tu-van form.aside {
      background: #F3F3F3;
      padding: 35px 0;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
      aside .block-form-tu-van form.aside .form-group {
        position: relative;
        padding: 0 35px; }
        aside .block-form-tu-van form.aside .form-group input {
          width: 100%;
          font-size: 16px;
          color: #707070;
          font-style: italic;
          line-height: 45px;
          padding: 0 40px 0 20px;
          border-radius: 9px;
          border: 1px solid #707070;
          margin-bottom: 5px; }
        aside .block-form-tu-van form.aside .form-group textarea {
          resize: none;
          padding: 15px 15px 55px 15px;
          width: 100%;
          height: 200px;
          color: #707070;
          border: 1px solid #707070;
          border-radius: 12px; }
        aside .block-form-tu-van form.aside .form-group .icon {
          position: absolute;
          right: 50px;
          top: 50%;
          transform: translateY(-50%); }
        aside .block-form-tu-van form.aside .form-group .submit {
          position: absolute;
          right: 50px;
          bottom: 30px;
          background: none;
          border: none; }
          aside .block-form-tu-van form.aside .form-group .submit span {
            font-size: 16px;
            color: #2B2B2B; }
  @media (max-width: 1024.98px) {
    aside {
      margin: 0 0 25px 0; }
      aside .block-service .title {
        background: #F3F3F3;
        box-shadow: none; }
        aside .block-service .title h3 {
          padding-left: 25px; }
      aside .block-service .list-item-service {
        box-shadow: none; }
        aside .block-service .list-item-service figure {
          border-left: 1px solid #F3F3F3;
          border-right: 1px solid #F3F3F3;
          border-bottom: 1px solid #F3F3F3; }
          aside .block-service .list-item-service figure .icon {
            display: none; }
          aside .block-service .list-item-service figure figcaption {
            margin-left: 25px; }
          aside .block-service .list-item-service figure:after {
            content: none; }
      aside .block-form-tu-van {
        margin-top: 20px; }
        aside .block-form-tu-van .title {
          box-shadow: none; }
          aside .block-form-tu-van .title h3 {
            padding-left: 25px; }
        aside .block-form-tu-van form.aside {
          padding: 25px 0;
          box-shadow: none; }
          aside .block-form-tu-van form.aside .form-group {
            padding: 0 25px; } }
  @media (max-width: 767.98px) {
    aside .block-service .title {
      height: 50px; }
      aside .block-service .title h3 {
        font-size: 16px;
        line-height: 50px; }
    aside .block-service .list-item-service figure {
      height: 50px; }
      aside .block-service .list-item-service figure figcaption span {
        font-size: 14px; }
    aside .block-form-tu-van .title {
      height: 50px; }
      aside .block-form-tu-van .title h3 {
        font-size: 16px;
        line-height: 50px; }
    aside .block-form-tu-van form.aside .form-group textarea {
      padding: 15px 15px 40px 15px; }
    aside .block-form-tu-van form.aside .form-group .submit {
      right: 30px;
      bottom: 15px; } }

.home-banner .slider-HomeBanner figure {
  position: relative;
  height: 70vh; }
  .home-banner .slider-HomeBanner figure figcaption {
    position: absolute;
    color: rgba(75, 75, 75, 0.9);
    left: 15%;
    transform: translateX(-15%);
    top: 155px;
    z-index: 1; }
    .home-banner .slider-HomeBanner figure figcaption .title {
      font-size: 80px;
      letter-spacing: 5px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 20px; }
    .home-banner .slider-HomeBanner figure figcaption .description {
      font-size: 21px; }

.home-banner .swiper-container .swiper-slide figure img {
  transition-delay: 1s;
  transition: 1s all ease-in-out;
  transform-origin: 0 0;
  animation: leaves 5s ease-in-out infinite alternate; }

@-webkit-keyframes leaves {
  0% {
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.025); } }

@keyframes leaves {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.025); } }

.home-banner .swiper-container .swiper-slide figure figcaption .title {
  transform: translateX(-200%);
  transition: 1.3s all ease-in-out; }

.home-banner .swiper-container .swiper-slide figure figcaption .description {
  transform: translateX(-200%);
  transition: 1.3s all ease-in-out;
  transition-delay: 1s; }

.home-banner .swiper-container .swiper-slide.swiper-slide-active figure img {
  transform: scale(1.1); }

.home-banner .swiper-container .swiper-slide.swiper-slide-active figure figcaption .title,
.home-banner .swiper-container .swiper-slide.swiper-slide-active figure figcaption .description {
  transform: translateX(0); }

.home-banner .swiper-container .swiper-button-next {
  background-image: url(../img/icons/banner-slider-next.svg);
  background-size: 30px;
  background: rgba(255, 255, 255, 0.3);
  background-position: 5px;
  background-repeat: no-repeat;
  right: -36px;
  width: 73px;
  height: 73px;
  z-index: 1;
  border-radius: 50%;
  transition: .3s all ease-in-out; }
  @media (min-width: 1025px) {
    .home-banner .swiper-container .swiper-button-next:hover {
      border-radius: 50% 0 0 50%;
      right: -20px; } }

.home-banner .swiper-container .swiper-button-prev {
  background-image: url(../img/icons/banner-slider-prev.svg);
  background-size: 30px;
  background: rgba(255, 255, 255, 0.3);
  background-position: 35px;
  background-repeat: no-repeat;
  left: -36px;
  width: 73px;
  height: 73px;
  z-index: 1;
  border-radius: 50%;
  transition: .3s all ease-in-out; }
  @media (min-width: 1025px) {
    .home-banner .swiper-container .swiper-button-prev:hover {
      border-radius: 0 50% 50% 0;
      left: -20px; } }

@media (max-width: 1024.98px) {
  .home-banner .slider-HomeBanner figure {
    position: relative;
    height: auto; }
    .home-banner .slider-HomeBanner figure figcaption {
      left: 100px;
      top: 50px; } }

@media (max-width: 767.98px) {
  .home-banner .slider-HomeBanner figure figcaption {
    left: 40px;
    top: 30px; }
    .home-banner .slider-HomeBanner figure figcaption .title {
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 10px; }
    .home-banner .slider-HomeBanner figure figcaption .description {
      font-size: 12px; }
  .home-banner .swiper-container .swiper-button-next {
    background-size: 15px;
    right: -25px;
    width: 44px;
    height: 44px; }
  .home-banner .swiper-container .swiper-button-prev {
    background-size: 15px;
    background-position: 25px;
    left: -25px;
    width: 44px;
    height: 44px; } }

.dealer-locator figure.big-img img {
  width: 100%; }

.dealer-locator figure.big-img figcaption {
  position: absolute;
  color: #FFFFFF;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .dealer-locator figure.big-img figcaption h3 {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 45px; }
  .dealer-locator figure.big-img figcaption p {
    font-size: 22px; }

.dealer-locator figure.small-img {
  cursor: pointer;
  position: relative;
  height: 380px;
  overflow: hidden; }
  .dealer-locator figure.small-img img {
    transition: .5s all ease-in-out; }
  .dealer-locator figure.small-img figcaption {
    position: absolute;
    width: 100%;
    padding: 0 50px;
    color: #FFFFFF;
    bottom: 45px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    transition: .5s all ease-in-out; }
    .dealer-locator figure.small-img figcaption h3 {
      text-align: center;
      font-size: 25px;
      font-weight: 700; }
    .dealer-locator figure.small-img figcaption p {
      font-size: 16px;
      display: none; }
  .dealer-locator figure.small-img:after {
    content: '';
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: .5s all ease-in-out;
    background: #bc2e31;
    background: -moz-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
    background: linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1); }
  .dealer-locator figure.small-img:hover img {
    transform: scale(1.1); }
  .dealer-locator figure.small-img:hover figcaption {
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -50%); }
    .dealer-locator figure.small-img:hover figcaption h3 {
      text-align: left; }
    .dealer-locator figure.small-img:hover figcaption p {
      display: block; }
  .dealer-locator figure.small-img:hover:after {
    opacity: 1; }

@media (max-width: 1024.98px) {
  .dealer-locator {
    padding: 0 15px; }
    .dealer-locator figure.big-img {
      display: none; }
    .dealer-locator figure.small-img {
      height: auto; }
      .dealer-locator figure.small-img figcaption {
        position: absolute;
        width: 100%;
        padding: 0 50px;
        color: #FFFFFF;
        bottom: 45px;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        transition: .5s all ease-in-out; }
        .dealer-locator figure.small-img figcaption h3 {
          text-align: center;
          font-size: 25px;
          font-weight: 700; }
        .dealer-locator figure.small-img figcaption p {
          font-size: 16px;
          display: none; } }

.home-about {
  background: #FFFFFF;
  padding: 135px; }
  .home-about .box-content {
    display: block;
    width: 845px;
    margin: 0 auto; }
    .home-about .box-content p {
      color: #2B2B2B;
      text-align: center;
      font-size: 24px; }
  @media (max-width: 1024.98px) {
    .home-about {
      padding: 60px 0; }
      .home-about .box-content {
        width: unset; } }
  @media (max-width: 767.98px) {
    .home-about .box-content p {
      font-size: 16px; } }

.introduce {
  margin-top: 45px; }
  .introduce .title {
    color: #2B2B2B; }
    .introduce .title span {
      font-size: 24px;
      font-weight: 400; }
    .introduce .title h3 {
      font-size: 34px;
      font-weight: 700; }
  .introduce .description {
    margin-top: 35px; }
    .introduce .description p {
      font-size: 18px;
      color: #4D4D4D;
      margin-bottom: 15px; }
      .introduce .description p.bold {
        font-weight: 700;
        color: #2B2B2B; }
  .introduce figure {
    margin-top: 60px; }
    .introduce figure img {
      width: 100%; }
  @media (max-width: 1024.98px) {
    .introduce {
      margin-top: 30px; }
      .introduce .title span {
        font-size: 18px; }
      .introduce .title h3 {
        font-size: 24px; }
      .introduce .description {
        margin-top: 15px; }
      .introduce figure {
        margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .introduce .description p {
      font-size: 16px; } }

.sologan {
  margin-top: 78px; }
  .sologan ul {
    list-style: none;
    padding: 0; }
  .sologan .block-content {
    position: relative;
    width: 100%;
    height: 140px;
    background: #FCFCFC; }
    .sologan .block-content .content {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%); }
      .sologan .block-content .content span {
        display: block;
        text-align: center;
        font-size: 18px;
        color: #2B2B2B;
        margin-bottom: 5px; }
      .sologan .block-content .content .list-item {
        display: flex;
        justify-content: center; }
        .sologan .block-content .content .list-item .item {
          margin-right: 25px;
          font-size: 25px;
          color: #2B2B2B;
          font-weight: 700; }
          .sologan .block-content .content .list-item .item img {
            margin-bottom: 6px;
            margin-left: 6px; }
  @media (max-width: 1024.98px) {
    .sologan {
      margin-top: 0; } }
  @media (max-width: 767.98px) {
    .sologan {
      display: none; } }

.service-list {
  margin: 75px 0; }
  .service-list ul {
    list-style: none;
    padding: 0; }
  .service-list h4 {
    font-size: 18px;
    font-weight: 700;
    color: #2B2B2B;
    margin-bottom: 20px; }
  .service-list .list-img {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px; }
    .service-list .list-img .item {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 7.5px;
      margin-bottom: 15px; }
  .service-list .list-item-service {
    padding-left: 10px; }
    .service-list .list-item-service .item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px; }
      .service-list .list-item-service .item h5 {
        flex: 0 0 40%;
        max-width: 40%;
        font-size: 18px;
        font-weight: 700;
        color: #2B2B2B; }
      .service-list .list-item-service .item ul {
        flex: 0 0 60%;
        max-width: 60%; }
        .service-list .list-item-service .item ul li {
          font-size: 18px;
          color: #707070; }
  @media (max-width: 1024.98px) {
    .service-list {
      margin: 15px 0; } }
  @media (max-width: 767.98px) {
    .service-list h4 {
      font-size: 16px; }
    .service-list .list-item-service {
      padding-left: 0px; }
      .service-list .list-item-service .item {
        margin-bottom: 15px; }
        .service-list .list-item-service .item h5 {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
          font-size: 16px; }
        .service-list .list-item-service .item ul {
          flex: 0 0 100%;
          max-width: 100%; }
          .service-list .list-item-service .item ul li {
            font-size: 16px; } }

.social-media {
  margin: 40px 20px; }
  .social-media h5 {
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    color: #707070; }
  .social-media .list-item {
    padding-left: 15px;
    display: inline-flex; }
    .social-media .list-item .item {
      padding-right: 20px; }
      .social-media .list-item .item:last-child {
        padding-right: 0; }
  @media (max-width: 1024.98px) {
    .social-media {
      margin: 0 0 25px 0; } }

.list-service-home .list-item {
  overflow: auto;
  width: 100%; }

.list-service-home .block-service {
  float: left;
  width: 50%; }
  .list-service-home .block-service figure.big-img {
    height: 500px;
    cursor: pointer;
    position: relative;
    overflow: hidden; }
    .list-service-home .block-service figure.big-img img {
      transition: .5s all ease-in-out; }
    .list-service-home .block-service figure.big-img figcaption {
      position: absolute;
      color: #FFFFFF;
      top: 50px;
      left: 50px;
      z-index: 1; }
      .list-service-home .block-service figure.big-img figcaption h5 {
        font-weight: 700;
        font-size: 37px;
        margin-bottom: 15px; }
      .list-service-home .block-service figure.big-img figcaption p {
        width: 80%;
        padding-left: 25px;
        margin-bottom: 15px; }
      .list-service-home .block-service figure.big-img figcaption > a {
        padding-left: 25px; }
    .list-service-home .block-service figure.big-img::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #4b4b4b;
      background: linear-gradient(180deg, rgba(75, 75, 75, 0.9) 0%, rgba(75, 75, 75, 0.199317) 100%, rgba(75, 75, 75, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgb(75, 75, 75)", endColorstr="rgb(75, 75, 75);", GradientType=1); }
    .list-service-home .block-service figure.big-img:hover img {
      transform: scale(1.1); }
  .list-service-home .block-service figure.small-img {
    height: 250px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap; }
    .list-service-home .block-service figure.small-img img {
      flex: 0 0 50%;
      max-width: 50%;
      transition: .3s all ease-in-out; }
    .list-service-home .block-service figure.small-img figcaption {
      position: relative;
      flex: 0 0 50%;
      max-width: 50%;
      color: #FFFFFF;
      background: #2B2B2B; }
      .list-service-home .block-service figure.small-img figcaption .box-content {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 88%; }
        .list-service-home .block-service figure.small-img figcaption .box-content h5 {
          margin-bottom: 5px;
          font-size: 25px;
          font-weight: 400; }
        .list-service-home .block-service figure.small-img figcaption .box-content p {
          -webkit-line-clamp: 3;
          margin-bottom: 20px;
          max-height: 72px;
          font-size: 16px; }
        .list-service-home .block-service figure.small-img figcaption .box-content > a {
          padding-left: 25px; }
    .list-service-home .block-service figure.small-img:hover img {
      transform: scale(1.1); }
  .list-service-home .block-service:nth-child(3) figure, .list-service-home .block-service:nth-child(6) figure {
    flex-direction: row-reverse; }
    .list-service-home .block-service:nth-child(3) figure figcaption, .list-service-home .block-service:nth-child(6) figure figcaption {
      background: #F3F3F3;
      color: #2B2B2B; }
      .list-service-home .block-service:nth-child(3) figure figcaption svg g, .list-service-home .block-service:nth-child(6) figure figcaption svg g {
        stroke: #040404; }
      .list-service-home .block-service:nth-child(3) figure figcaption svg path, .list-service-home .block-service:nth-child(6) figure figcaption svg path {
        fill: #040404; }
  .list-service-home .block-service:nth-child(5) {
    float: right; }

@media (max-width: 1024.98px) {
  .list-service-home .block-service {
    width: 100%; } }

@media (max-width: 767.98px) {
  .list-service-home .block-service figure.big-img {
    height: auto; }
    .list-service-home .block-service figure.big-img figcaption {
      top: 25px;
      left: 20px; }
      .list-service-home .block-service figure.big-img figcaption h5 {
        font-size: 16px;
        margin-bottom: 15px; }
      .list-service-home .block-service figure.big-img figcaption p {
        padding-left: 10px;
        font-size: 14px; }
      .list-service-home .block-service figure.big-img figcaption > a {
        padding-left: 10px; }
  .list-service-home .block-service figure.small-img {
    position: relative;
    height: auto; }
    .list-service-home .block-service figure.small-img img {
      height: 210px; }
    .list-service-home .block-service figure.small-img figcaption .box-content {
      background: transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .list-service-home .block-service figure.small-img figcaption .box-content h5 {
        font-size: 16px;
        margin-bottom: 15px; }
      .list-service-home .block-service figure.small-img figcaption .box-content p {
        padding-left: 0;
        font-size: 14px; }
      .list-service-home .block-service figure.small-img figcaption .box-content > a {
        padding-left: 0; } }

.form-tuvan {
  padding: 25px 0 140px;
  background: #FFFFFF;
  background-image: url(../img/form-tuvan/bg-section.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .form-tuvan .btn {
    margin: 0 auto; }
  .form-tuvan .block-form-tuvan {
    margin-top: 100px; }
    .form-tuvan .block-form-tuvan h3 {
      font-size: 37px;
      font-weight: 700;
      color: #2B2B2B;
      text-align: center;
      margin-bottom: 55px; }
    .form-tuvan .block-form-tuvan .form-group {
      position: relative; }
      .form-tuvan .block-form-tuvan .form-group input {
        width: 100%;
        font-size: 16px;
        color: #707070;
        font-style: italic;
        line-height: 45px;
        padding: 0 40px 0 20px;
        border-radius: 9px;
        border: 1px solid #707070; }
      .form-tuvan .block-form-tuvan .form-group textarea {
        resize: none;
        margin-top: 25px;
        padding: 15px;
        width: 100%;
        height: 200px;
        color: #707070;
        border: 1px solid #707070;
        border-radius: 12px; }
      .form-tuvan .block-form-tuvan .form-group .icon {
        position: absolute;
        right: 35px;
        top: 50%;
        transform: translateY(-50%); }
      .form-tuvan .block-form-tuvan .form-group .submit {
        position: absolute;
        bottom: 30px;
        right: 30px;
        background: none;
        border: none; }
        .form-tuvan .block-form-tuvan .form-group .submit span {
          font-size: 16px;
          color: #2B2B2B; }
  @media (max-width: 1024.98px) {
    .form-tuvan {
      padding: 25px 0px; }
      .form-tuvan .block-form-tuvan {
        margin-top: 50px; }
        .form-tuvan .block-form-tuvan .form-group {
          margin-bottom: 15px; }
          .form-tuvan .block-form-tuvan .form-group textarea {
            margin-top: 0px; }
          .form-tuvan .block-form-tuvan .form-group:last-child {
            margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .form-tuvan .btn {
      width: 190px; }
    .form-tuvan .block-form-tuvan h3 {
      font-size: 20px;
      margin-bottom: 25px; } }

.homwe-news {
  margin-top: 125px; }
  .homwe-news .title {
    margin: 0 auto;
    width: fit-content;
    text-align: center; }
    .homwe-news .title h3 {
      line-height: 1;
      font-size: 37px;
      color: #2B2B2B; }
    .homwe-news .title > a {
      display: inline-block;
      position: relative;
      font-size: 16px;
      font-style: italic;
      color: #707070;
      padding-right: 15px; }
      .homwe-news .title > a::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 5px;
        height: 10px;
        background-image: url(../img/icons/see-news.svg);
        background-size: cover;
        background-repeat: no-repeat; }
  .homwe-news .list-item {
    margin-top: 60px; }
    .homwe-news .list-item .item {
      margin-bottom: 55px; }
      .homwe-news .list-item .item figure {
        display: flex; }
        .homwe-news .list-item .item figure .box-img {
          position: relative;
          overflow: hidden;
          min-width: 180px;
          height: 150px;
          margin-right: 15px; }
          .homwe-news .list-item .item figure .box-img img {
            transition: .5s all ease-in-out; }
          .homwe-news .list-item .item figure .box-img .icon {
            background-image: url(../img/icons/service-white.svg);
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: -100%;
            transform: translate(-50%, -100%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: .5s all ease-in-out; }
          .homwe-news .list-item .item figure .box-img:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: .5s all ease-in-out;
            background: #3e4095;
            background: -moz-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E4095", endColorstr="#3E4095", GradientType=1); }
        .homwe-news .list-item .item figure figcaption {
          color: #2B2B2B; }
          .homwe-news .list-item .item figure figcaption .title-news {
            font-size: 14px;
            height: 42px;
            -webkit-line-clamp: 2;
            margin-bottom: 3px; }
          .homwe-news .list-item .item figure figcaption .time {
            font-size: 13px;
            letter-spacing: 4px;
            margin-bottom: 25px; }
          .homwe-news .list-item .item figure figcaption .description {
            font-size: 14px;
            height: 60px;
            -webkit-line-clamp: 3; }
      .homwe-news .list-item .item:hover figure .box-img img {
        transform: scale(1.1); }
      .homwe-news .list-item .item:hover figure .box-img .icon {
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: visible; }
      .homwe-news .list-item .item:hover figure .box-img:after {
        opacity: 1; }
  @media (max-width: 1024.98px) {
    .homwe-news {
      margin-top: 50px; }
      .homwe-news .title h3 {
        font-size: 24px; }
      .homwe-news .title > a {
        font-size: 14px; }
      .homwe-news .list-item {
        margin-top: 20px; }
        .homwe-news .list-item .item {
          margin-bottom: 20px; }
          .homwe-news .list-item .item figure {
            display: block;
            padding-bottom: 20px;
            border-bottom: 1px solid #F3F3F3; }
            .homwe-news .list-item .item figure .box-img {
              min-width: 100%;
              height: 280px;
              margin-bottom: 15px;
              margin-right: 0; }
            .homwe-news .list-item .item figure figcaption {
              color: #2B2B2B; }
              .homwe-news .list-item .item figure figcaption .title-news {
                font-size: 16px;
                height: 42px;
                -webkit-line-clamp: 2;
                margin-bottom: 3px; }
              .homwe-news .list-item .item figure figcaption .time {
                font-size: 13px;
                margin-bottom: 15px; }
              .homwe-news .list-item .item figure figcaption .description {
                height: 40px;
                -webkit-line-clamp: 2; } }

.tintuc {
  margin: 45px 0 20px; }
  .tintuc .title {
    margin-bottom: 50px; }
    .tintuc .title h3 {
      font-size: 34px;
      color: #2B2B2B;
      font-weight: 700; }
  .tintuc .list-news .item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #F3F3F3; }
    .tintuc .list-news .item figure {
      display: flex; }
      .tintuc .list-news .item figure .box-img {
        position: relative;
        min-width: 282px;
        overflow: hidden;
        margin-right: 45px; }
        .tintuc .list-news .item figure .box-img img {
          transition: .5s all ease-in-out; }
        .tintuc .list-news .item figure .box-img .icon {
          background-image: url(../img/icons/service-white.svg);
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: -100%;
          transform: translate(-50%, -100%);
          z-index: 1;
          opacity: 0;
          pointer-events: none;
          transition: .5s all ease-in-out; }
        .tintuc .list-news .item figure .box-img:after {
          content: '';
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          transition: .5s all ease-in-out;
          background: #bc2e31;
          background: -moz-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
          background: -webkit-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
          background: linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1); }
      .tintuc .list-news .item figure figcaption {
        align-self: center;
        color: #2B2B2B; }
        .tintuc .list-news .item figure figcaption .title-news {
          font-size: 24px;
          max-height: 70px;
          font-weight: 700;
          -webkit-line-clamp: 2;
          margin-bottom: 3px; }
        .tintuc .list-news .item figure figcaption .time {
          font-size: 18px;
          letter-spacing: 4px;
          margin-bottom: 25px; }
        .tintuc .list-news .item figure figcaption .description {
          font-size: 18px;
          height: 110px;
          -webkit-line-clamp: 4;
          color: #4D4D4D; }
    .tintuc .list-news .item:last-child {
      margin-bottom: 0; }
    .tintuc .list-news .item:hover figure .box-img img {
      transform: scale(1.1); }
    .tintuc .list-news .item:hover figure .box-img .icon {
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      pointer-events: visible; }
    .tintuc .list-news .item:hover figure .box-img:after {
      opacity: 1; }
  @media (max-width: 1024.98px) {
    .tintuc {
      margin: 30px 0 0 0; }
      .tintuc .title {
        margin-bottom: 25px; }
        .tintuc .title h3 {
          font-size: 34px;
          color: #2B2B2B;
          font-weight: 700; }
      .tintuc .list-news .item figure .box-img {
        margin-right: 30px;
        min-width: 200px; }
      .tintuc .list-news .item figure figcaption .title-news {
        font-size: 18px;
        max-height: 27px;
        -webkit-line-clamp: 1; }
      .tintuc .list-news .item figure figcaption .time {
        font-size: 14px;
        margin-bottom: 15px; }
      .tintuc .list-news .item figure figcaption .description {
        font-size: 16px;
        height: 70px;
        -webkit-line-clamp: 3; } }
  @media (max-width: 767.98px) {
    .tintuc .title h3 {
      font-size: 24px; }
    .tintuc .list-news .item {
      margin-bottom: 15px;
      padding-bottom: 10px; }
      .tintuc .list-news .item figure {
        display: block; }
        .tintuc .list-news .item figure .box-img {
          height: 280px;
          min-width: unset;
          margin-right: 0; }
        .tintuc .list-news .item figure figcaption {
          margin-top: 15px; }
          .tintuc .list-news .item figure figcaption .title-news {
            font-size: 14px;
            max-height: 42px;
            font-weight: 400;
            -webkit-line-clamp: 2;
            margin-bottom: 3px; }
          .tintuc .list-news .item figure figcaption .time {
            font-size: 12px;
            margin-bottom: 10px; }
          .tintuc .list-news .item figure figcaption .description {
            font-size: 14px;
            height: 85px;
            -webkit-line-clamp: 4; }
      .tintuc .list-news .item:last-child {
        margin-bottom: 0; }
      .tintuc .list-news .item:hover figure .box-img img {
        transform: scale(1.1); }
      .tintuc .list-news .item:hover figure .box-img .icon {
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: visible; }
      .tintuc .list-news .item:hover figure .box-img:after {
        opacity: 1; } }

.aside-same-news {
  margin: 60px 0 20px; }
  .aside-same-news .title {
    position: relative;
    height: 65px;
    background: #F3F3F3;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    .aside-same-news .title h3 {
      font-size: 24px;
      color: #3E4095;
      font-weight: 400;
      line-height: 65px;
      padding-left: 12px;
      border-bottom: 1px solid #3E4095; }
  .aside-same-news .list-same-news {
    background: #F3F3F3; }
    .aside-same-news .list-same-news .item {
      padding: 20px 12px 20px;
      border-bottom: 1px solid #FFFFFF; }
      .aside-same-news .list-same-news .item figure {
        display: flex; }
        .aside-same-news .list-same-news .item figure .box-img {
          position: relative;
          min-width: 148px;
          height: 120px;
          overflow: hidden;
          margin-right: 25px; }
          .aside-same-news .list-same-news .item figure .box-img img {
            transition: .5s all ease-in-out; }
          .aside-same-news .list-same-news .item figure .box-img .icon {
            background-image: url(../img/icons/service-white.svg);
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: -100%;
            transform: translate(-50%, -100%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: .5s all ease-in-out; }
          .aside-same-news .list-same-news .item figure .box-img:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: .5s all ease-in-out;
            background: #3e4095;
            background: -moz-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E4095", endColorstr="#3E4095", GradientType=1); }
        .aside-same-news .list-same-news .item figure figcaption {
          align-self: center;
          color: #2B2B2B; }
          .aside-same-news .list-same-news .item figure figcaption .title-news {
            font-size: 14px;
            max-height: 63px;
            -webkit-line-clamp: 3;
            margin-bottom: 10px; }
          .aside-same-news .list-same-news .item figure figcaption .time {
            font-size: 13px;
            letter-spacing: 4px; }
      .aside-same-news .list-same-news .item:hover figure .box-img img {
        transform: scale(1.1); }
      .aside-same-news .list-same-news .item:hover figure .box-img .icon {
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: visible; }
      .aside-same-news .list-same-news .item:hover figure .box-img:after {
        opacity: 1; }
  @media (max-width: 1024.98px) {
    .aside-same-news {
      margin-top: 45px; }
      .aside-same-news .title {
        height: unset;
        background: transparent;
        box-shadow: none;
        margin-bottom: 5px; }
        .aside-same-news .title h3 {
          text-decoration: underline;
          line-height: unset;
          padding-left: 0;
          border-bottom: none; }
      .aside-same-news .list-same-news {
        background: #FFFFFF; }
        .aside-same-news .list-same-news .item {
          padding: 15px 0 10px;
          border-bottom: 1px solid #F3F3F3; }
          .aside-same-news .list-same-news .item figure .box-img {
            min-width: 250px;
            height: 170px;
            margin-right: 20px; }
          .aside-same-news .list-same-news .item figure figcaption {
            align-self: center;
            color: #2B2B2B; }
            .aside-same-news .list-same-news .item figure figcaption .title-news {
              font-size: 14px;
              max-height: 63px;
              -webkit-line-clamp: 3; }
            .aside-same-news .list-same-news .item figure figcaption .time {
              font-size: 13px;
              letter-spacing: 4px; }
          .aside-same-news .list-same-news .item:hover figure .box-img img {
            transform: scale(1.1); }
          .aside-same-news .list-same-news .item:hover figure .box-img .icon {
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            pointer-events: visible; }
          .aside-same-news .list-same-news .item:hover figure .box-img:after {
            opacity: 1; } }
  @media (max-width: 767.98px) {
    .aside-same-news .list-same-news .item figure {
      display: block; }
      .aside-same-news .list-same-news .item figure .box-img {
        min-width: unset;
        margin-right: 0;
        height: 280px; }
      .aside-same-news .list-same-news .item figure figcaption {
        margin-top: 15px; }
        .aside-same-news .list-same-news .item figure figcaption .title-news {
          font-size: 14px;
          max-height: 42px;
          -webkit-line-clamp: 2;
          margin-bottom: 5px; }
        .aside-same-news .list-same-news .item figure figcaption .time {
          font-size: 13px;
          letter-spacing: 4px; }
    .aside-same-news .list-same-news .item:hover figure .box-img img {
      transform: scale(1.1); }
    .aside-same-news .list-same-news .item:hover figure .box-img .icon {
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      pointer-events: visible; }
    .aside-same-news .list-same-news .item:hover figure .box-img:after {
      opacity: 1; } }

.list-clients {
  margin: 110px 0 15px; }
  .list-clients .list-slider {
    position: relative; }
    .list-clients .list-slider .btn-noClick {
      margin: 0 auto 35px; }
    .list-clients .list-slider figure img {
      display: block;
      margin: 0 auto; }
    .list-clients .list-slider::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 70px;
      background: #707070;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); }
  .list-clients .swiper-button-prev {
    left: 0;
    background-image: url(../img/icons/prev-slider-clients.svg); }
  .list-clients .swiper-button-next {
    right: 0;
    background-image: url(../img/icons/next-slider-clients.svg); }
  @media (max-width: 1024.98px) {
    .list-clients {
      margin: 30px 0 15px; }
      .list-clients .list-slider .btn-noClick {
        width: 100px; }
        .list-clients .list-slider .btn-noClick span {
          font-size: 14px; } }

.tienich-table {
  display: flex;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .tienich-table {
      flex-wrap: wrap; } }
  .tienich-table .table-img {
    flex: 0 0 340px;
    max-width: 340px; }
    .tienich-table .table-img table {
      height: 100%; }
    @media (max-width: 767.98px) {
      .tienich-table .table-img {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px; } }
  .tienich-table .table-content {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      .tienich-table .table-content {
        flex: 0 0 100%;
        max-width: 100%; } }
  .tienich-table .content {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
    overflow-x: auto; }
  .tienich-table table {
    width: 100%; }
    .tienich-table table thead th {
      height: 65px;
      background: #3E4095;
      color: white;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 400;
      text-align: center; }
    .tienich-table table tbody td {
      font-size: 18px;
      text-align: center; }
      @media (max-width: 1024.98px) {
        .tienich-table table tbody td {
          font-size: 14px; } }
    .tienich-table table th,
    .tienich-table table td {
      border: 1px solid #C1C1C1;
      padding: 10px 15px; }
      .tienich-table table th img,
      .tienich-table table td img {
        width: 100%; }

.tienich-chuyendoi table thead th {
  text-align: center; }

.tienich-chuyendoi table tbody th {
  text-align: center;
  font-weight: 400;
  color: #3E4095;
  text-decoration: underline; }

.tienich-tigia table thead th {
  font-size: 18px;
  text-align: center; }

.tienich-tudien .tab-container {
  margin-bottom: 60px; }

.tienich-tudien .nav-characters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .tienich-tudien .nav-characters a {
    flex: 0 0 50px;
    max-width: 50px;
    border-radius: 5px;
    font-weight: 700;
    color: #3E4095;
    cursor: pointer;
    text-decoration: underline !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    transition: all .3s ease-out; }
    .tienich-tudien .nav-characters a.active {
      color: #BC2E31;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); }

.tienich-tudien .tab-content .item {
  font-size: 18px;
  margin-bottom: 25px; }
  .tienich-tudien .tab-content .item h4 {
    font-weight: 400;
    color: #3E4095; }
  .tienich-tudien .tab-content .item p {
    color: #4D4D4D; }

.lien-he-truc-tiep {
  margin: 60px 0 55px;
  padding-right: 40px; }
  .lien-he-truc-tiep .main-title {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 65px;
    color: #2B2B2B; }
  .lien-he-truc-tiep .list-contact {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px; }
    .lien-he-truc-tiep .list-contact .item {
      flex: 33.33333%;
      max-width: 33.33333%;
      padding: 0 6px;
      margin-bottom: 25px; }
      .lien-he-truc-tiep .list-contact .item figure {
        padding-bottom: 10px;
        border-bottom: 1px solid #F3F3F3; }
        .lien-he-truc-tiep .list-contact .item figure img {
          width: 100%; }
        .lien-he-truc-tiep .list-contact .item figure figcaption {
          margin: 15px 0px 0px 5px; }
          .lien-he-truc-tiep .list-contact .item figure figcaption h3 {
            font-size: 16px;
            font-weight: 700;
            color: #3E4095; }
          .lien-he-truc-tiep .list-contact .item figure figcaption span {
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
            color: #2B2B2B;
            margin-bottom: 15px; }
          .lien-he-truc-tiep .list-contact .item figure figcaption p {
            font-size: 14px;
            color: #4D4D4D; }
  @media (max-width: 1024.98px) {
    .lien-he-truc-tiep {
      margin: 30px 0;
      padding-right: 15px; }
      .lien-he-truc-tiep .main-title {
        margin-bottom: 25px; } }
  @media (max-width: 767.98px) {
    .lien-he-truc-tiep .main-title {
      font-size: 24px; }
    .lien-he-truc-tiep .list-contact .item {
      flex: 100%;
      max-width: 100%;
      margin-bottom: 20px; }
      .lien-he-truc-tiep .list-contact .item figure figcaption {
        margin: 15px 0px 0px 5px; }
        .lien-he-truc-tiep .list-contact .item figure figcaption h3 {
          font-size: 18px; }
        .lien-he-truc-tiep .list-contact .item figure figcaption span {
          font-size: 14px; }
      .lien-he-truc-tiep .list-contact .item:last-child {
        margin-bottom: 0; } }

.he-thong-cua-hang {
  margin: 60px 0 70px; }
  .he-thong-cua-hang .col-lg-4 {
    padding-right: 30px; }
  .he-thong-cua-hang .main-title {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #2B2B2B; }
  .he-thong-cua-hang .block-form-tu-van {
    margin-top: 30px; }
    .he-thong-cua-hang .block-form-tu-van form .form-group {
      position: relative;
      padding: 0;
      margin-bottom: 13px; }
      .he-thong-cua-hang .block-form-tu-van form .form-group input {
        width: 100%;
        font-size: 16px;
        color: #707070;
        font-style: italic;
        line-height: 45px;
        border-radius: 9px;
        border: 1px solid #707070;
        padding: 0 45px 0 12px; }
      .he-thong-cua-hang .block-form-tu-van form .form-group textarea {
        resize: none;
        padding: 15px 15px 55px 15px;
        width: 100%;
        height: 200px;
        color: #707070;
        border: 1px solid #707070;
        border-radius: 12px; }
      .he-thong-cua-hang .block-form-tu-van form .form-group .icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%); }
      .he-thong-cua-hang .block-form-tu-van form .form-group .submit {
        position: absolute;
        right: 10px;
        bottom: 20px;
        background: none;
        border: none; }
        .he-thong-cua-hang .block-form-tu-van form .form-group .submit span {
          font-size: 16px;
          color: #2B2B2B; }
  .he-thong-cua-hang p {
    font-size: 14px;
    color: #707070; }
  .he-thong-cua-hang #map {
    height: 495px; }
  .he-thong-cua-hang .map-item {
    margin: 80px 0;
    cursor: pointer; }
    .he-thong-cua-hang .map-item h4 {
      font-size: 18px;
      color: #3E4095;
      font-weight: 400;
      margin-bottom: 15px; }
    .he-thong-cua-hang .map-item p {
      font-size: 18px;
      color: #2B2B2B;
      word-spacing: 8.7px; }
    .he-thong-cua-hang .map-item .hot-line {
      margin-top: 10px; }
      .he-thong-cua-hang .map-item .hot-line b {
        color: #BC2E31; }
  @media (max-width: 1024.98px) {
    .he-thong-cua-hang {
      margin: 30px 0; }
      .he-thong-cua-hang .col-lg-4 {
        padding-right: 15px; }
      .he-thong-cua-hang p {
        color: #000000; }
      .he-thong-cua-hang .block-form-tu-van {
        margin-top: 15px; }
        .he-thong-cua-hang .block-form-tu-van form .form-group {
          margin-bottom: 5px; }
      .he-thong-cua-hang .map-item {
        margin: 30px 0; }
        .he-thong-cua-hang .map-item p {
          font-size: 16px; } }

.form-dangky-booking {
  margin: 45px 0px 100px;
  padding-right: 40px; }
  .form-dangky-booking .form-row {
    margin: 0 -10px; }
    .form-dangky-booking .form-row.mt-70 {
      margin-top: 70px; }
  .form-dangky-booking .title {
    margin-bottom: 50px; }
    .form-dangky-booking .title h3 {
      font-size: 34px;
      font-weight: 700;
      color: #2B2B2B; }
  .form-dangky-booking button {
    float: right; }
  .form-dangky-booking .form-group {
    position: relative;
    padding: 0 10px;
    margin-bottom: 20px; }
    .form-dangky-booking .form-group.small {
      padding: 0 5px; }
    .form-dangky-booking .form-group input[type='text'] {
      width: 100%;
      font-size: 16px;
      color: #3E4095;
      font-weight: 700;
      font-style: italic;
      line-height: 45px;
      padding: 0 20px;
      border: 1px solid #C1C1C1;
      font-style: italic; }
      .form-dangky-booking .form-group input[type='text']::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .form-dangky-booking .form-group input[type='number'] {
      width: 100%;
      font-size: 16px;
      color: #3E4095;
      font-weight: 700;
      font-style: italic;
      line-height: 45px;
      padding-left: 20px;
      border: 1px solid #C1C1C1;
      font-style: italic; }
      .form-dangky-booking .form-group input[type='number']::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .form-dangky-booking .form-group input[type='date'] {
      display: block;
      color: #3E4095;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      width: 100%;
      height: 45px;
      font-size: 16px;
      font-style: italic; }
    .form-dangky-booking .form-group #buttonchoose {
      width: 150px;
      line-height: 30px;
      border-radius: 23px;
      border: 1px solid #C1C1C1;
      text-align: center;
      font-size: 16px;
      color: #707070;
      margin-top: 10px; }
    .form-dangky-booking .form-group textarea.small {
      font-size: 16px;
      resize: none;
      padding: 15px;
      font-weight: 700;
      width: 100%;
      height: 90px;
      color: #3E4095;
      border: 1px solid #C1C1C1; }
      .form-dangky-booking .form-group textarea.small::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .form-dangky-booking .form-group textarea.big {
      resize: none;
      padding: 15px;
      width: 100%;
      font-weight: 700;
      height: 160px;
      color: #3E4095;
      border: 1px solid #C1C1C1; }
      .form-dangky-booking .form-group textarea.big::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .form-dangky-booking .form-group label {
      cursor: pointer;
      width: 100%;
      font-size: 18px;
      color: #2B2B2B;
      margin-bottom: 10px; }
      .form-dangky-booking .form-group label.color-red {
        color: #BC2E31; }
      .form-dangky-booking .form-group label.block {
        color: #FFFFFF;
        line-height: 45px;
        padding: 0 15px;
        background: #3E4095; }
    .form-dangky-booking .form-group select {
      cursor: pointer;
      width: 100%;
      background: none;
      height: 45px;
      color: #3E4095;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      font-style: italic; }
  @media (max-width: 1024.98px) {
    .form-dangky-booking {
      margin: 25px 0;
      padding-right: 15px; }
      .form-dangky-booking .form-row.mt-70 {
        margin-top: 0; }
      .form-dangky-booking .title {
        margin-bottom: 25px; }
        .form-dangky-booking .title h3 {
          font-size: 34px;
          font-weight: 700;
          color: #2B2B2B; }
      .form-dangky-booking button {
        display: block;
        float: unset;
        margin: 0 auto; } }

.yeu-cau-bao-gia {
  margin: 60px 0 135px;
  padding-right: 40px; }
  .yeu-cau-bao-gia .form-row {
    margin: 0 -10px; }
    .yeu-cau-bao-gia .form-row.small {
      margin: 0 -5px; }
  .yeu-cau-bao-gia .title h3 {
    font-size: 34px;
    color: #2B2B2B;
    margin-bottom: 20px; }
  .yeu-cau-bao-gia p {
    font-size: 14px;
    color: #2B2B2B;
    font-style: italic;
    margin-bottom: 20px; }
  .yeu-cau-bao-gia .yeu-cau-bao-gia-table {
    overflow-x: auto;
    margin-bottom: 20px; }
    .yeu-cau-bao-gia .yeu-cau-bao-gia-table h5 {
      font-size: 18px;
      margin-bottom: 20px; }
  .yeu-cau-bao-gia .form-group {
    position: relative;
    padding: 0 10px;
    margin-bottom: 20px; }
    .yeu-cau-bao-gia .form-group.small {
      padding: 0 5px; }
    .yeu-cau-bao-gia .form-group h5 {
      font-size: 18px; }
    .yeu-cau-bao-gia .form-group input[type='text'] {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      color: #3E4095;
      font-style: italic;
      line-height: 45px;
      padding: 0 40px 0 20px;
      border: 1px solid #C1C1C1;
      font-style: italic; }
      .yeu-cau-bao-gia .form-group input[type='text']::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .yeu-cau-bao-gia .form-group input[type='date'] {
      display: block;
      color: #3E4095;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      width: 100%;
      height: 45px;
      font-size: 16px;
      font-style: italic; }
    .yeu-cau-bao-gia .form-group input[type='date']:not(.has-value)::before {
      content: attr(placeholder); }
    .yeu-cau-bao-gia .form-group textarea.small {
      font-size: 16px;
      resize: none;
      padding: 15px;
      width: 100%;
      height: 140px;
      color: #3E4095;
      border: 1px solid #C1C1C1;
      font-weight: 700; }
      .yeu-cau-bao-gia .form-group textarea.small::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .yeu-cau-bao-gia .form-group textarea.big {
      resize: none;
      padding: 15px;
      width: 100%;
      height: 215px;
      color: #3E4095;
      border: 1px solid #C1C1C1;
      font-weight: 700; }
      .yeu-cau-bao-gia .form-group textarea.big::placeholder {
        color: rgba(112, 112, 112, 0.5);
        font-weight: 400; }
    .yeu-cau-bao-gia .form-group label {
      cursor: pointer;
      width: 100%;
      color: #707070;
      line-height: 45px;
      padding: 0 15px;
      background: #f3f3f3; }
    .yeu-cau-bao-gia .form-group select {
      cursor: pointer;
      width: 100%;
      background: none;
      height: 45px;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      color: #3E4095;
      font-style: italic; }
      .yeu-cau-bao-gia .form-group select option:disabled {
        color: #707070; }
    .yeu-cau-bao-gia .form-group .icon {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%); }
    .yeu-cau-bao-gia .form-group .submit {
      position: absolute;
      bottom: 30px;
      right: 30px;
      background: none;
      border: none; }
      .yeu-cau-bao-gia .form-group .submit span {
        font-size: 16px;
        color: #2B2B2B; }
  .yeu-cau-bao-gia table,
  .yeu-cau-bao-gia thead,
  .yeu-cau-bao-gia tbody,
  .yeu-cau-bao-gia tr,
  .yeu-cau-bao-gia td,
  .yeu-cau-bao-gia th {
    border-collapse: collapse;
    border: 1px solid #c1c1c1; }
    .yeu-cau-bao-gia table input,
    .yeu-cau-bao-gia table textarea,
    .yeu-cau-bao-gia thead input,
    .yeu-cau-bao-gia thead textarea,
    .yeu-cau-bao-gia tbody input,
    .yeu-cau-bao-gia tbody textarea,
    .yeu-cau-bao-gia tr input,
    .yeu-cau-bao-gia tr textarea,
    .yeu-cau-bao-gia td input,
    .yeu-cau-bao-gia td textarea,
    .yeu-cau-bao-gia th input,
    .yeu-cau-bao-gia th textarea {
      border: 0;
      width: 100%; }
    .yeu-cau-bao-gia table.remove input,
    .yeu-cau-bao-gia thead.remove input,
    .yeu-cau-bao-gia tbody.remove input,
    .yeu-cau-bao-gia tr.remove input,
    .yeu-cau-bao-gia td.remove input,
    .yeu-cau-bao-gia th.remove input {
      cursor: pointer;
      background: #f3f3f3;
      color: #707070; }
  .yeu-cau-bao-gia table {
    width: 100%;
    text-align: center;
    min-width: 745px; }
    .yeu-cau-bao-gia table thead tr th {
      height: 50px;
      border: 1px solid #c1c1c1;
      background: #f3f3f3;
      color: #707070; }
    .yeu-cau-bao-gia table tbody tr.table-tong-cong td input {
      font-weight: 700; }
    .yeu-cau-bao-gia table tbody tr.table-tong-cong td:first-child input {
      text-align: center; }
    .yeu-cau-bao-gia table tbody input[type='number'] {
      width: 100%; }
  @media (max-width: 1024.98px) {
    .yeu-cau-bao-gia {
      margin: 30px 0;
      padding-right: 15px; }
      .yeu-cau-bao-gia .col-lg-8 {
        padding-right: 15px; }
      .yeu-cau-bao-gia .title h3 {
        margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .yeu-cau-bao-gia {
      margin: 15px 0; }
      .yeu-cau-bao-gia .title h3 {
        font-size: 24px;
        margin-bottom: 5px; }
      .yeu-cau-bao-gia .form-group {
        margin-bottom: 10px; }
        .yeu-cau-bao-gia .form-group textarea.small {
          padding: 9px;
          height: 45px; }
        .yeu-cau-bao-gia .form-group textarea.big {
          padding: 9px;
          height: 160px; }
        .yeu-cau-bao-gia .form-group .submit {
          right: 15px;
          bottom: 15px; } }

.table-responsive {
  display: block;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 30px; }

.request {
  margin: 60px 0 122px;
  padding-right: 40px; }
  .request .form-row {
    margin: 0 -10px; }
  .request h3 {
    font-size: 34px;
    color: #2B2B2B;
    margin-bottom: 45px; }
  .request button {
    float: right; }
  .request .form-group {
    position: relative;
    padding: 0 10px;
    margin-bottom: 20px; }
    .request .form-group.small {
      padding: 0 5px; }
    .request .form-group input[type='text'] {
      width: 100%;
      font-size: 16px;
      color: #707070;
      font-style: italic;
      line-height: 45px;
      padding: 0 20px;
      border: 1px solid #C1C1C1;
      font-style: italic; }
    .request .form-group input[type='number'] {
      width: 100%;
      font-size: 16px;
      color: #707070;
      font-style: italic;
      line-height: 45px;
      padding-left: 20px;
      border: 1px solid #C1C1C1;
      font-style: italic; }
    .request .form-group input[type='date'] {
      display: block;
      color: #707070;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      width: 100%;
      height: 45px;
      font-size: 16px;
      font-style: italic; }
    .request .form-group textarea.small {
      font-size: 16px;
      resize: none;
      padding: 15px;
      width: 100%;
      height: 90px;
      color: #707070;
      border: 1px solid #C1C1C1; }
    .request .form-group textarea.big {
      resize: none;
      padding: 15px;
      width: 100%;
      height: 160px;
      color: #707070;
      border: 1px solid #C1C1C1; }
    .request .form-group label {
      cursor: pointer;
      width: 100%;
      font-size: 18px;
      color: #2B2B2B;
      margin-bottom: 10px; }
      .request .form-group label.color-red {
        color: #BC2E31; }
      .request .form-group label.block {
        color: #FFFFFF;
        line-height: 45px;
        padding: 0 15px;
        background: #3E4095; }
    .request .form-group select {
      width: 100%;
      background: none;
      height: 45px;
      color: #707070;
      border: 1px solid #C1C1C1;
      padding: 10px 15px;
      font-style: italic; }
    @media (max-width: 1199.98px) {
      .request .form-group .tableForm .col-xl-6:nth-child(2) {
        margin-top: 10px; } }
    .request .form-group .tableForm:nth-child(1) td.tac-vu .delete {
      display: none; }
    .request .form-group .tableForm + .tableForm {
      margin-top: 25px; }
      .request .form-group .tableForm + .tableForm td.tac-vu .add {
        display: none; }
    .request .form-group table {
      border: 1px solid #C1C1C1;
      width: 100%; }
      .request .form-group table th,
      .request .form-group table td {
        border: 1px solid #C1C1C1; }
      .request .form-group table th {
        font-size: 16px;
        color: white;
        background: #3e4095;
        text-align: center;
        height: 50px;
        padding: 10px;
        width: 25%; }
      .request .form-group table td.TypeContainer {
        width: 130px; }
      .request .form-group table td.ContainerNo {
        width: 100px; }
      .request .form-group table td.SealNo {
        width: 80px; }
      .request .form-group table td.GW {
        width: 60px; }
      .request .form-group table td.CBM {
        width: 60px; }
      .request .form-group table td.NumberOfPackage {
        width: 105px; }
      .request .form-group table td.Unit {
        width: 115px; }
      .request .form-group table td select,
      .request .form-group table td input {
        border: none !important; }
      .request .form-group table td.tac-vu {
        width: 81px;
        text-align: center; }
        .request .form-group table td.tac-vu img {
          cursor: pointer;
          display: inline-block;
          margin: 0 5px; }
  .request .tableFormHidden {
    width: 100%;
    margin-top: 20px; }
  @media (max-width: 1024.98px) {
    .request {
      margin: 30px 0;
      padding-right: 15px; }
      .request h3 {
        margin-bottom: 15px; }
      .request .form-group input[type='number'] {
        padding: 0 7px; }
      .request .form-group table td.tac-vu {
        width: 100px; }
      .request button {
        display: block;
        margin: 0 auto;
        float: unset; } }
  @media (max-width: 767.98px) {
    .request .form-group label {
      font-size: 16px; }
    .request h3 {
      font-size: 24px; } }

.library {
  margin: 60px 0 115px; }
  .library .title h3 {
    font-size: 34px;
    color: #2B2B2B; }
  .library .list-item {
    display: flex;
    flex-wrap: wrap;
    margin: 60px -8px 0; }
    .library .list-item .item {
      cursor: pointer;
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 8px;
      margin-bottom: 30px; }
      .library .list-item .item figure {
        padding-bottom: 15px;
        border-bottom: 1px solid #F3F3F3; }
        .library .list-item .item figure .box-img {
          position: relative;
          height: 220px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
          .library .list-item .item figure .box-img .icon {
            background-image: url(../img/icons/library-img.svg);
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: .5s all ease-in-out; }
          .library .list-item .item figure .box-img:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: .5s all ease-in-out;
            background: #bc2e31;
            background: -moz-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
            background: linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.199317) 100%, rgba(188, 46, 49, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1); }
        .library .list-item .item figure .box-video {
          position: relative;
          background: #dddddd;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          height: 210px;
          width: 100%; }
          .library .list-item .item figure .box-video .icon {
            background-image: url(../img/icons/library-video.svg);
            width: 47px;
            height: 47px;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: .5s all ease-in-out; }
          .library .list-item .item figure .box-video:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: .5s all ease-in-out;
            background: #3e4095;
            background: -moz-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            background: linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.199317) 100%, rgba(62, 64, 149, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E4095", endColorstr="#3E4095", GradientType=1); }
        .library .list-item .item figure figcaption {
          margin-top: 15px; }
          .library .list-item .item figure figcaption h5 {
            font-size: 18px;
            color: #2B2B2B;
            font-weight: 400;
            margin-bottom: 5px; }
            .library .list-item .item figure figcaption h5 span {
              display: inline-block;
              margin-right: 5px;
              color: #707070; }
          .library .list-item .item figure figcaption p {
            font-size: 13px;
            color: #707070;
            letter-spacing: 4px; }
      .library .list-item .item:hover figure .box-img .icon {
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: visible; }
      .library .list-item .item:hover figure .box-img:after {
        opacity: 1; }
      .library .list-item .item:hover figure .box-video .icon {
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: visible; }
      .library .list-item .item:hover figure .box-video:after {
        opacity: 1; }
  .library .see-more {
    margin: 35px auto 0; }
  @media (max-width: 1024.98px) {
    .library {
      margin: 60px 0; }
      .library .list-item {
        margin: 30px -8px 0; }
        .library .list-item .item {
          flex: 0 0 50%;
          max-width: 50%; } }
  @media (max-width: 767.98px) {
    .library .list-item .item {
      flex: 0 0 100%;
      max-width: 100%; } }

.FAQ {
  margin-top: 60px;
  padding-right: 40px; }
  .FAQ .title h3 {
    font-size: 34px;
    font-weight: 700;
    color: #2B2B2B; }
  .FAQ .detail-group {
    margin-top: 25px; }
    .FAQ .detail-group .item-group {
      margin-bottom: 10px; }
      .FAQ .detail-group .item-group .question {
        cursor: pointer;
        position: relative;
        background: #F3F3F3;
        padding: 25px 60px;
        border-radius: 12px;
        user-select: none; }
        .FAQ .detail-group .item-group .question p {
          font-size: 18px;
          color: #2B2B2B; }
        .FAQ .detail-group .item-group .question .arrow-icon {
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          transition: .2s all ease-in-out; }
          .FAQ .detail-group .item-group .question .arrow-icon.active {
            transform: translateY(-50%) rotate(180deg); }
      .FAQ .detail-group .item-group .answer {
        display: none;
        margin-top: 5px;
        padding: 25px 50px;
        border: 1px solid #707070;
        border-radius: 12px;
        margin-bottom: 30px; }
        .FAQ .detail-group .item-group .answer p {
          font-size: 16px;
          color: #707070; }
  @media (max-width: 1024.98px) {
    .FAQ {
      margin: 30px 0;
      padding-right: 15px; } }
  @media (max-width: 767.98px) {
    .FAQ .title {
      font-size: 24px; }
    .FAQ .detail-group {
      margin-top: 25px; }
      .FAQ .detail-group .item-group .question {
        padding: 25px 60px 25px 20px; }
        .FAQ .detail-group .item-group .question p {
          font-size: 16px; }
      .FAQ .detail-group .item-group .answer {
        padding: 25px 20px; } }

#doc-form {
  margin-top: 45px;
  margin-bottom: 200px; }
  #doc-form .wrapper {
    display: flex; }
  #doc-form ul {
    flex: 0 0 40%;
    max-width: 40%;
    list-style: none;
    padding: 0; }
    #doc-form ul.order-detail {
      margin-left: 10px; }
      #doc-form ul.order-detail li {
        position: relative;
        font-weight: 500;
        padding-left: 10px;
        display: flex; }
        #doc-form ul.order-detail li p {
          flex: 0 0 50%;
          max-width: 50%; }
        #doc-form ul.order-detail li::before {
          content: "-";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0; }
  #doc-form .receiver-name {
    align-self: flex-end;
    flex: 0 0 60%;
    max-width: 60%; }
  #doc-form .header-docForm .thong-tin-cong-ty {
    color: #3E4095; }
  #doc-form .content-docForm {
    margin-top: 30px; }
    #doc-form .content-docForm .title {
      text-align: center;
      font-weight: 700; }
      #doc-form .content-docForm .title .title-big {
        font-size: 40px; }
      #doc-form .content-docForm .title .title-small {
        font-size: 20px;
        font-style: italic; }
    #doc-form .content-docForm .box-right {
      margin-left: auto;
      padding-right: 15px; }
      #doc-form .content-docForm .box-right span {
        position: relative;
        display: inline-block;
        padding-left: 5px;
        font-weight: 700; }
        #doc-form .content-docForm .box-right span.title {
          padding-right: 50px;
          width: 180px; }
          #doc-form .content-docForm .box-right span.title:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
    #doc-form .content-docForm .kinh-gui {
      padding-left: 170px; }
      #doc-form .content-docForm .kinh-gui span {
        position: relative;
        width: 80%;
        font-size: 20px;
        font-weight: 700;
        padding-left: 5px;
        margin-left: auto; }
        #doc-form .content-docForm .kinh-gui span.title {
          width: 190px; }
          #doc-form .content-docForm .kinh-gui span.title:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
    #doc-form .content-docForm .kinh-gui-small {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      font-weight: 700; }
      #doc-form .content-docForm .kinh-gui-small p {
        flex: 0 0 10%;
        display: inline-block;
        width: 100px;
        font-style: italic;
        text-decoration: underline; }
      #doc-form .content-docForm .kinh-gui-small span {
        flex: 0 0 90%;
        margin-left: auto; }
      #doc-form .content-docForm .kinh-gui-small.big-font {
        font-size: 20px; }
    #doc-form .content-docForm .text {
      margin: 15px 0; }
      #doc-form .content-docForm .text p.bold {
        font-weight: 700; }
      #doc-form .content-docForm .text p.italic {
        font-style: italic; }
      #doc-form .content-docForm .text p.text-right {
        text-align: right; }
      #doc-form .content-docForm .text p.text-left {
        text-align: left; }
      #doc-form .content-docForm .text p.underline {
        text-decoration: underline; }
      #doc-form .content-docForm .text p span.bold {
        font-weight: 700; }
    #doc-form .content-docForm .thong-tin-don-hang {
      margin-top: 15px; }
      #doc-form .content-docForm .thong-tin-don-hang .item {
        margin-bottom: 15px; }
        #doc-form .content-docForm .thong-tin-don-hang .item p:nth-child(2) {
          font-style: italic;
          font-size: 14px; }
        #doc-form .content-docForm .thong-tin-don-hang .item p.bold {
          font-weight: 700; }
        #doc-form .content-docForm .thong-tin-don-hang .item p.text-center {
          text-align: center; }
    #doc-form .content-docForm .bang-thong-tin-don-hang table {
      width: 100%;
      border: 1px solid #000000; }
      #doc-form .content-docForm .bang-thong-tin-don-hang table thead tr th {
        border-right: 1px solid #000000;
        padding: 5px;
        text-align: center; }
        #doc-form .content-docForm .bang-thong-tin-don-hang table thead tr th p:last-child {
          font-size: 13px;
          font-style: italic; }
      #doc-form .content-docForm .bang-thong-tin-don-hang table tbody tr {
        border-top: 1px solid #000000; }
        #doc-form .content-docForm .bang-thong-tin-don-hang table tbody tr td {
          padding: 20px;
          text-align: center;
          border-right: 1px solid #000000; }
          #doc-form .content-docForm .bang-thong-tin-don-hang table tbody tr td p.bold {
            font-weight: 700; }
    #doc-form .content-docForm .mot-so-luu-y {
      margin-top: 30px;
      font-weight: 700;
      line-height: 30px; }
    #doc-form .content-docForm .tong-chi-phi {
      width: 100%;
      display: flex;
      padding-right: 15px;
      justify-content: space-between;
      margin-top: 15px;
      margin-left: 50%;
      font-weight: 700; }
    #doc-form .content-docForm .date {
      margin-top: 150px;
      text-align: right; }
    #doc-form .content-docForm .nguoi-gui span i {
      font-size: 14px; }
    #doc-form .content-docForm .nguoi-gui p:first-child {
      font-weight: 700; }
    #doc-form .content-docForm .nguoi-gui .row:nth-child(2) {
      margin-top: 10px; }
    #doc-form .content-docForm .giao-hang-theo {
      margin-top: 10px;
      font-weight: 700; }
      #doc-form .content-docForm .giao-hang-theo h5 {
        text-align: center;
        padding: 10px;
        border: 1px solid #000000; }
    #doc-form .content-docForm .danh-sach-cac-chi-phi {
      margin-top: 40px; }
      #doc-form .content-docForm .danh-sach-cac-chi-phi .ten-chi-phi {
        position: relative;
        padding-left: 35px; }
        #doc-form .content-docForm .danh-sach-cac-chi-phi .ten-chi-phi:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 26px;
          height: 26px;
          background-image: url(../img/icons/checked.svg); }
      #doc-form .content-docForm .danh-sach-cac-chi-phi ul {
        display: flex;
        justify-content: space-between; }
        #doc-form .content-docForm .danh-sach-cac-chi-phi ul.tong-tien {
          width: 50%;
          padding: 0 15px;
          font-weight: 700;
          margin-left: auto;
          padding-top: 10px;
          border-top: 1px solid #000000; }
      #doc-form .content-docForm .danh-sach-cac-chi-phi .row {
        margin-bottom: 10px; }
    #doc-form .content-docForm .bill {
      font-weight: 700; }
      #doc-form .content-docForm .bill h3 {
        text-decoration: underline;
        margin-bottom: 10px; }
      #doc-form .content-docForm .bill span {
        font-weight: 400; }
      #doc-form .content-docForm .bill .item {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between; }
    #doc-form .content-docForm .routing h3 {
      font-weight: 700;
      text-decoration: underline;
      margin-bottom: 10px; }
    #doc-form .content-docForm .routing table {
      width: 100%;
      border: 1px solid #000000; }
      #doc-form .content-docForm .routing table thead tr th {
        font-size: 13px;
        border-right: 1px solid #000000;
        padding: 5px;
        text-align: center;
        text-decoration: underline; }
    #doc-form .content-docForm .routing tbody tr {
      border-top: 1px solid #000000; }
      #doc-form .content-docForm .routing tbody tr td {
        padding: 5px;
        border-right: 1px solid #000000;
        text-align: center; }
    #doc-form .content-docForm .thoi-gian-van-chuyen {
      margin-top: 30px; }
      #doc-form .content-docForm .thoi-gian-van-chuyen .item {
        display: flex;
        font-weight: 700; }
        #doc-form .content-docForm .thoi-gian-van-chuyen .item p {
          position: relative;
          width: 200px;
          margin-right: 10px; }
          #doc-form .content-docForm .thoi-gian-van-chuyen .item p:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
    #doc-form .content-docForm .ten-muc-luc {
      margin-top: 10px;
      display: flex; }
      #doc-form .content-docForm .ten-muc-luc p {
        font-weight: 700;
        text-decoration: underline;
        margin-right: 10px; }
        #doc-form .content-docForm .ten-muc-luc p:nth-child(2) {
          font-weight: 400;
          text-decoration: unset; }
    #doc-form .content-docForm .noi-dung-muc-luc {
      font-size: 14px;
      margin-top: 10px;
      padding-left: 30px; }
      #doc-form .content-docForm .noi-dung-muc-luc .muc-a {
        display: flex;
        flex-wrap: wrap; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-a p {
          position: relative;
          flex: 0 0 10%;
          display: inline-block;
          width: 100px;
          text-decoration: underline;
          font-weight: 700; }
          #doc-form .content-docForm .noi-dung-muc-luc .muc-a p:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-a span {
          flex: 0 0 90%;
          margin-left: auto;
          padding-left: 10px; }
      #doc-form .content-docForm .noi-dung-muc-luc .muc-c .ten-cac-dieu-khoan {
        display: flex;
        flex-wrap: wrap; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-c .ten-cac-dieu-khoan .left {
          flex: 0 0 30px;
          max-width: 30px; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-c .ten-cac-dieu-khoan .right {
          margin-right: auto; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-c .ten-cac-dieu-khoan.mt-20 {
          margin-top: 20px; }
      #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan {
        margin-top: 20px;
        padding-left: 30px; }
        #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan .item {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px; }
          #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan .item .left {
            position: relative;
            flex: 0 0 30%;
            max-width: 30%; }
            #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan .item .left:after {
              content: '*';
              position: absolute;
              left: -10px;
              top: 0; }
            #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan .item .left:before {
              content: ':';
              position: absolute;
              right: 0;
              top: 0; }
          #doc-form .content-docForm .noi-dung-muc-luc .muc-c .noi-dung-chi-tiet-dieu-khoan .item .right {
            flex: 0 0 70%;
            max-width: 70%;
            padding-left: 10px; }
    #doc-form .content-docForm .chu-ky {
      margin-top: 50px; }
      #doc-form .content-docForm .chu-ky .nguoi-ky {
        text-align: center; }
    #doc-form .content-docForm .info-loading {
      margin-top: 20px; }
      #doc-form .content-docForm .info-loading .item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        #doc-form .content-docForm .info-loading .item p {
          position: relative;
          flex: 0 0 200px;
          width: 200px;
          font-weight: 700; }
          #doc-form .content-docForm .info-loading .item p:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
        #doc-form .content-docForm .info-loading .item span {
          flex: 0 0 700px;
          max-width: 700px;
          padding-left: 20px; }
    #doc-form .content-docForm .thong-tin-chi-tiet-loading {
      width: 70%;
      margin: 0 auto; }
      #doc-form .content-docForm .thong-tin-chi-tiet-loading .item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        #doc-form .content-docForm .thong-tin-chi-tiet-loading .item p {
          position: relative;
          flex: 0 0 30%;
          width: 30%;
          font-weight: 700; }
          #doc-form .content-docForm .thong-tin-chi-tiet-loading .item p:after {
            content: ':';
            position: absolute;
            right: 0;
            top: 0; }
        #doc-form .content-docForm .thong-tin-chi-tiet-loading .item span {
          flex: 0 70%;
          max-width: 70%;
          padding-left: 20px;
          margin-left: auto; }
    #doc-form .content-docForm .bang-booking {
      width: 1200px; }
      #doc-form .content-docForm .bang-booking table {
        width: 1200px; }
        #doc-form .content-docForm .bang-booking table.mt-10 {
          margin-top: 10px; }
      #doc-form .content-docForm .bang-booking tr td,
      #doc-form .content-docForm .bang-booking tr th {
        padding: 10px; }
        #doc-form .content-docForm .bang-booking tr td.col-talbe-8,
        #doc-form .content-docForm .bang-booking tr th.col-talbe-8 {
          width: 800.000004px; }
        #doc-form .content-docForm .bang-booking tr td.col-table-6,
        #doc-form .content-docForm .bang-booking tr th.col-table-6 {
          width: 600px; }
        #doc-form .content-docForm .bang-booking tr td.col-table-4,
        #doc-form .content-docForm .bang-booking tr th.col-table-4 {
          width: 399.9999996px; }
        #doc-form .content-docForm .bang-booking tr td.col-table-3,
        #doc-form .content-docForm .bang-booking tr th.col-table-3 {
          width: 300px; }
        #doc-form .content-docForm .bang-booking tr td.col-table-2,
        #doc-form .content-docForm .bang-booking tr th.col-table-2 {
          width: 200.000004px; }
        #doc-form .content-docForm .bang-booking tr td.col-table-12,
        #doc-form .content-docForm .bang-booking tr th.col-table-12 {
          width: 1200px; }
        #doc-form .content-docForm .bang-booking tr td.no-border-top,
        #doc-form .content-docForm .bang-booking tr th.no-border-top {
          border-top: none; }
        #doc-form .content-docForm .bang-booking tr td.no-border-bottom,
        #doc-form .content-docForm .bang-booking tr th.no-border-bottom {
          border-bottom: none; }
      #doc-form .content-docForm .bang-booking tr th {
        border-top: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        border-bottom: 0;
        font-size: 14px;
        font-style: italic;
        font-weight: 400; }
        #doc-form .content-docForm .bang-booking tr th.bold {
          font-weight: 700; }
      #doc-form .content-docForm .bang-booking tr td {
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        border-top: 0; }
        #doc-form .content-docForm .bang-booking tr td.border-top {
          border-top: 1px solid #000000; }
        #doc-form .content-docForm .bang-booking tr td.bold {
          font-weight: 700;
          font-size: 20px; }
    #doc-form .content-docForm .bang-hbl table {
      width: 100%; }
      #doc-form .content-docForm .bang-hbl table tr td,
      #doc-form .content-docForm .bang-hbl table tr th {
        padding: 10px;
        vertical-align: top; }
      #doc-form .content-docForm .bang-hbl table tr td {
        border: 1px solid #3E4095; }
        #doc-form .content-docForm .bang-hbl table tr td.text-center {
          text-align: center; }
        #doc-form .content-docForm .bang-hbl table tr td.no-border-top {
          border-top: none; }
        #doc-form .content-docForm .bang-hbl table tr td.no-border-bottom {
          border-bottom: none; }
        #doc-form .content-docForm .bang-hbl table tr td.bold {
          font-weight: 700;
          font-size: 20px; }
        #doc-form .content-docForm .bang-hbl table tr td h3 {
          font-size: 40px;
          color: #3E4095;
          text-align: center; }
        #doc-form .content-docForm .bang-hbl table tr td span {
          display: block;
          font-size: 13px;
          color: #3E4095; }
        #doc-form .content-docForm .bang-hbl table tr td p.blue {
          color: #3E4095; }
      #doc-form .content-docForm .bang-hbl table tr th {
        border-right: 1px solid #3E4095;
        border-left: 1px solid #3E4095;
        border-bottom: 1px solid #3E4095;
        color: #3E4095;
        font-size: 13px; }
        #doc-form .content-docForm .bang-hbl table tr th.no-border-bottom {
          border-bottom: none; }
    #doc-form .content-docForm .bang-hbl .bottom-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 200px;
      padding-bottom: 40px;
      border-right: 1px solid #3E4095;
      border-left: 1px solid #3E4095; }
      #doc-form .content-docForm .bang-hbl .bottom-box .text-1,
      #doc-form .content-docForm .bang-hbl .bottom-box .text-2,
      #doc-form .content-docForm .bang-hbl .bottom-box .ngay-thang-nam {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        text-align: center;
        padding: 0 30px; }
      #doc-form .content-docForm .bang-hbl .bottom-box .ngay-thang-nam {
        padding-right: 40px;
        text-align: right; }
    #doc-form .content-docForm .bang-hawb table {
      width: 100%; }
      #doc-form .content-docForm .bang-hawb table tr td,
      #doc-form .content-docForm .bang-hawb table tr th {
        padding: 10px;
        vertical-align: top; }
        #doc-form .content-docForm .bang-hawb table tr td.vertical-center,
        #doc-form .content-docForm .bang-hawb table tr th.vertical-center {
          vertical-align: middle; }
        #doc-form .content-docForm .bang-hawb table tr td.vertical-bottom,
        #doc-form .content-docForm .bang-hawb table tr th.vertical-bottom {
          vertical-align: bottom; }
        #doc-form .content-docForm .bang-hawb table tr td.text-center,
        #doc-form .content-docForm .bang-hawb table tr th.text-center {
          text-align: center; }
      #doc-form .content-docForm .bang-hawb table tr td {
        border: 1px solid #3E4095; }
        #doc-form .content-docForm .bang-hawb table tr td.none-border-right {
          border-right: 1px solid transparent; }
        #doc-form .content-docForm .bang-hawb table tr td.no-border-top {
          border-top: none; }
        #doc-form .content-docForm .bang-hawb table tr td.no-border-bottom {
          border-bottom: none; }
        #doc-form .content-docForm .bang-hawb table tr td.bold {
          font-weight: 700;
          font-size: 20px; }
        #doc-form .content-docForm .bang-hawb table tr td.w-50 {
          width: 50%; }
        #doc-form .content-docForm .bang-hawb table tr td h3 {
          font-size: 40px;
          color: #3E4095;
          text-align: center; }
          #doc-form .content-docForm .bang-hawb table tr td h3.text-left {
            text-align: left; }
        #doc-form .content-docForm .bang-hawb table tr td h4 {
          font-size: 20px;
          color: #3E4095;
          text-align: center; }
          #doc-form .content-docForm .bang-hawb table tr td h4.text-left {
            text-align: left; }
        #doc-form .content-docForm .bang-hawb table tr td span {
          display: block;
          font-size: 13px;
          color: #3E4095; }
        #doc-form .content-docForm .bang-hawb table tr td p.blue {
          color: #3E4095; }
        #doc-form .content-docForm .bang-hawb table tr td p.text-left {
          text-align: left; }
        #doc-form .content-docForm .bang-hawb table tr td p.text-center {
          text-align: center; }
        #doc-form .content-docForm .bang-hawb table tr td p.italic {
          font-style: italic; }
        #doc-form .content-docForm .bang-hawb table tr td p.border-bottom-dotted {
          border-bottom: 2px dotted #000000; }
        #doc-form .content-docForm .bang-hawb table tr td p.mt-10 {
          margin-top: 10px; }
      #doc-form .content-docForm .bang-hawb table tr th {
        border-right: 1px solid #3E4095;
        border-left: 1px solid #3E4095;
        border-bottom: 1px solid #3E4095;
        color: #3E4095;
        font-size: 13px; }
        #doc-form .content-docForm .bang-hawb table tr th.no-border-bottom {
          border-bottom: none; }
    #doc-form .content-docForm .bang-hawb .ngay-thang-nam {
      padding-top: 200px;
      padding-right: 40px;
      padding-bottom: 40px;
      text-align: right;
      border-right: 1px solid #3E4095;
      border-left: 1px solid #3E4095; }
