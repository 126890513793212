aside {
	margin: 60px 0 80px;

	.block-service {
		.title {
			position  : relative;
			height    : 65px;
			background: #F3F3F3;
			box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

			h3 {
				font-size   : 24px;
				color       : #3E4095;
				font-weight : 400;
				line-height : 65px;
				padding-left: 12px;
			}
		}

		.list-item-service {
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

			figure {
				position     : relative;
				height       : 80px;
				display      : flex;
				align-items  : center;
				border-bottom: 1px dotted #707070;
				transition   : .3s all ease-in-out;

				.icon {
					width     : 70px;
					text-align: center;
				}

				figcaption {
					margin-left: 20px;

					span {
						font-size: 18px;
						color    : #2B2B2B;
					}
				}

				&:after {
					content  : '';
					position : absolute;
					top      : 50%;
					left     : 70px;
					transform: translateY(-50%);
					width    : 1px;
					height   : 60px;
					border   : .5px dotted #707070;
				}

				&:hover {
					background: #3E4095;

					.icon {
						svg {
							path {
								fill: #FFFFFF;
							}
						}
					}

					figcaption {
						span {
							color: #FFFFFF;
						}
					}

					&:after {
						border: .5px dotted #FFFFFF;
					}
				}
			}
		}
	}

	.block-form-tu-van {
		margin-top: 80px;

		.title {
			position  : relative;
			height    : 65px;
			background: #BC2E31;
			box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

			h3 {
				font-size   : 24px;
				color       : #FFFFFF;
				font-weight : 400;
				line-height : 65px;
				padding-left: 12px;
			}
		}

		form.aside {
			background: #F3F3F3;
			padding   : 35px 0;
			box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

			.form-group {
				position: relative;
				padding : 0 35px;

				input {
					width        : 100%;
					font-size    : 16px;
					color        : #707070;
					font-style   : italic;
					line-height  : 45px;
					padding      : 0 40px 0 20px;
					border-radius: 9px;
					border       : 1px solid #707070;
					margin-bottom: 5px;
				}

				textarea {
					resize       : none;
					padding      : 15px 15px 55px 15px;
					width        : 100%;
					height       : 200px;
					color        : #707070;
					border       : 1px solid #707070;
					border-radius: 12px;
				}

				.icon {
					position : absolute;
					right    : 50px;
					top      : 50%;
					transform: translateY(-50%);
				}

				.submit {
					position  : absolute;
					right     : 50px;
					bottom    : 30px;
					background: none;
					border    : none;

					span {
						font-size: 16px;
						color    : #2B2B2B;
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin: 0 0 25px 0;

		.block-service {
			.title {
				background: #F3F3F3;
				box-shadow: none;

				h3 {
					padding-left: 25px;
				}
			}

			.list-item-service {
				box-shadow: none;

				figure {
					border-left  : 1px solid #F3F3F3;
					border-right : 1px solid #F3F3F3;
					border-bottom: 1px solid #F3F3F3;

					.icon {
						display: none;
					}

					figcaption {
						margin-left: 25px;
					}

					&:after {
						content: none;
					}
				}
			}
		}

		.block-form-tu-van {
			margin-top: 20px;

			.title {
				box-shadow: none;

				h3 {
					padding-left: 25px;
				}
			}

			form.aside {
				padding   : 25px 0;
				box-shadow: none;

				.form-group {
					padding: 0 25px;
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.block-service {
			.title {
				height: 50px;

				h3 {
					font-size  : 16px;
					line-height: 50px;
				}
			}

			.list-item-service {
				figure {
					height: 50px;

					figcaption {
						span {
							font-size: 14px;
						}
					}
				}
			}
		}

		.block-form-tu-van {
			.title {
				height: 50px;

				h3 {
					font-size  : 16px;
					line-height: 50px;
				}
			}

			form.aside {
				.form-group {
					textarea {
						padding: 15px 15px 40px 15px;
					}

					.submit {
						right : 30px;
						bottom: 15px;
					}
				}
			}
		}
	}
}