.he-thong-cua-hang {
	margin: 60px 0 70px;

	.col-lg-4 {
		padding-right: 30px;
	}

	.main-title {
		font-size    : 34px;
		font-weight  : 700;
		margin-bottom: 15px;
		color        : #2B2B2B;
	}

	.block-form-tu-van {
		margin-top: 30px;

		form {
			.form-group {
				position     : relative;
				padding      : 0;
				margin-bottom: 13px;

				input {
					width        : 100%;
					font-size    : 16px;
					color        : #707070;
					font-style   : italic;
					line-height  : 45px;
					border-radius: 9px;
					border       : 1px solid #707070;
					padding      : 0 45px 0 12px;
				}

				textarea {
					resize       : none;
					padding      : 15px 15px 55px 15px;
					width        : 100%;
					height       : 200px;
					color        : #707070;
					border       : 1px solid #707070;
					border-radius: 12px;
				}

				.icon {
					position : absolute;
					right    : 20px;
					top      : 50%;
					transform: translateY(-50%);
				}

				.submit {
					position  : absolute;
					right     : 10px;
					bottom    : 20px;
					background: none;
					border    : none;

					span {
						font-size: 16px;
						color    : #2B2B2B;
					}
				}
			}
		}
	}

	p {
		font-size: 14px;
		color    : #707070;
	}


	#map {
		height: 495px;
	}

	.map-item {
		margin: 80px 0;
		cursor: pointer;

		h4 {
			font-size    : 18px;
			color        : #3E4095;
			font-weight  : 400;
			margin-bottom: 15px;
		}

		p {
			font-size   : 18px;
			color       : #2B2B2B;
			word-spacing: 8.7px;
		}

		.hot-line {
			margin-top: 10px;

			b {
				color: #BC2E31;
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin: 30px 0;

		.col-lg-4 {
			padding-right: 15px;
		}

		p {
			color: #000000;
		}

		.block-form-tu-van {
			margin-top: 15px;

			form {
				.form-group {
					margin-bottom: 5px;
				}
			}
		}

		.map-item {
			margin: 30px 0;

			p {
				font-size: 16px;
			}
		}
	}
}