.form-tuvan {
	padding            : 25px 0 140px;
	background         : #FFFFFF;
	background-image   : url(../img/form-tuvan/bg-section.png);
	background-size    : cover;
	background-position: center center;
	background-repeat  : no-repeat;

	.btn {
		margin: 0 auto;
	}

	.block-form-tuvan {
		margin-top: 100px;

		h3 {
			font-size    : 37px;
			font-weight  : 700;
			color        : #2B2B2B;
			text-align   : center;
			margin-bottom: 55px;
		}

		.form-group {
			position: relative;

			input {
				width        : 100%;
				font-size    : 16px;
				color        : #707070;
				font-style   : italic;
				line-height  : 45px;
				padding      : 0 40px 0 20px;
				border-radius: 9px;
				border       : 1px solid #707070;
			}

			textarea {
				resize       : none;
				margin-top   : 25px;
				padding      : 15px;
				width        : 100%;
				height       : 200px;
				color        : #707070;
				border       : 1px solid #707070;
				border-radius: 12px;
			}

			.icon {
				position : absolute;
				right    : 35px;
				top      : 50%;
				transform: translateY(-50%);
			}

			.submit {
				position  : absolute;
				bottom    : 30px;
				right     : 30px;
				background: none;
				border    : none;

				span {
					font-size: 16px;
					color    : #2B2B2B;
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		padding: 25px 0px;

		.block-form-tuvan {
			margin-top: 50px;

			.form-group {
				margin-bottom: 15px;

				textarea {
					margin-top: 0px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

	}

	@media (max-width: 767.98px) {
		.btn {
			width: 190px;
		}

		.block-form-tuvan {

			h3 {
				font-size    : 20px;
				margin-bottom: 25px;
			}
		}
	}
}