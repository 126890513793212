.social-media {
	margin: 40px 20px;

	h5 {
		display    : inline-block;
		font-size  : 18px;
		font-weight: 300;
		font-style : italic;
		color      : #707070;
	}

	.list-item {
		padding-left: 15px;
		display     : inline-flex;

		.item {
			padding-right: 20px;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin: 0 0 25px 0;
	}
}