.tintuc {
	margin: 45px 0 20px;

	.title {
		margin-bottom: 50px;

		h3 {
			font-size  : 34px;
			color      : #2B2B2B;
			font-weight: 700;
		}
	}

	.list-news {
		.item {
			margin-bottom : 30px;
			padding-bottom: 30px;
			border-bottom : 1px solid #F3F3F3;

			figure {
				display: flex;

				.box-img {
					position    : relative;
					min-width   : 282px;
					overflow    : hidden;
					margin-right: 45px;

					img {
						transition: .5s all ease-in-out;
					}

					.icon {
						background-image : url(../img/icons/service-white.svg);
						width            : 30px;
						height           : 30px;
						background-repeat: no-repeat;

						position      : absolute;
						top           : 50%;
						left          : -100%;
						transform     : translate(-50%, -100%);
						z-index       : 1;
						opacity       : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;
					}

					&:after {
						content       : '';
						position      : absolute;
						opacity       : 0;
						width         : 100%;
						height        : 100%;
						top           : 0;
						left          : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;

						background: rgb(188, 46, 49);
						background: -moz-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
						background: -webkit-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
						background: linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
						filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1);
					}
				}

				figcaption {
					align-self: center;
					color     : #2B2B2B;

					.title-news {
						font-size         : 24px;
						max-height        : 70px;
						font-weight       : 700;
						-webkit-line-clamp: 2;
						margin-bottom     : 3px;
					}

					.time {
						font-size     : 18px;
						letter-spacing: 4px;
						margin-bottom : 25px;
					}

					.description {
						font-size         : 18px;
						height            : 110px;
						-webkit-line-clamp: 4;
						color             : #4D4D4D;
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				figure {
					.box-img {
						img {
							transform: scale(1.1);
						}

						.icon {
							left          : 50%;
							transform     : translate(-50%, -50%);
							opacity       : 1;
							pointer-events: visible;
						}

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin: 30px 0 0 0;

		.title {
			margin-bottom: 25px;

			h3 {
				font-size  : 34px;
				color      : #2B2B2B;
				font-weight: 700;
			}
		}

		.list-news {
			.item {
				figure {
					.box-img {
						margin-right: 30px;
						min-width   : 200px;
					}

					figcaption {
						.title-news {
							font-size         : 18px;
							max-height        : 27px;
							-webkit-line-clamp: 1;
						}

						.time {
							font-size    : 14px;
							margin-bottom: 15px;
						}

						.description {
							font-size         : 16px;
							height            : 70px;
							-webkit-line-clamp: 3;
						}
					}
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.title {
			h3 {
				font-size: 24px;
			}
		}

		.list-news {
			.item {
				margin-bottom : 15px;
				padding-bottom: 10px;

				figure {
					display: block;

					.box-img {
						height      : 280px;
						min-width   : unset;
						margin-right: 0;
					}

					figcaption {
						margin-top: 15px;

						.title-news {
							font-size         : 14px;
							max-height        : 42px;
							font-weight       : 400;
							-webkit-line-clamp: 2;
							margin-bottom     : 3px;
						}

						.time {
							font-size    : 12px;
							margin-bottom: 10px;
						}

						.description {
							font-size         : 14px;
							height            : 85px;
							-webkit-line-clamp: 4;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:hover {
					figure {
						.box-img {
							img {
								transform: scale(1.1);
							}

							.icon {
								left          : 50%;
								transform     : translate(-50%, -50%);
								opacity       : 1;
								pointer-events: visible;
							}

							&:after {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}