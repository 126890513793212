.home-banner {
	.slider-HomeBanner {
		figure {
			position: relative;
			height  : 70vh;

			figcaption {
				position : absolute;
				color    : rgba(75, 75, 75, .9);
				left     : 15%;
				transform: translateX(-15%);
				top      : 155px;
				z-index  : 1;

				.title {
					font-size     : 80px;
					letter-spacing: 5px;
					font-weight   : 400;
					line-height   : 1.2;
					margin-bottom : 20px;
				}

				.description {
					font-size: 21px;
				}
			}

			// &:after {
			// 	content : '';
			// 	position: absolute;
			// 	left    : 0;
			// 	top     : 0;
			// 	width   : 100%;
			// 	height  : 100%;

			// 	background: rgb(188, 46, 49);
			// 	background: -moz-linear-gradient(90deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0) 25%);
			// 	background: -webkit-linear-gradient(90deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0) 25%);
			// 	background: linear-gradient(90deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0) 25%);
			// 	filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1);
			// }
		}
	}

	// SET HIỆU ỨNG CHO SLIDER
	.swiper-container {
		.swiper-slide {
			figure {
				img {
					transition-delay: 1s;
					transition      : 1s all ease-in-out;
					transform-origin: 0 0;
					animation       : leaves 5s ease-in-out infinite alternate;

					@-webkit-keyframes leaves {
						0% {
							-webkit-transform: scale(1);
						}

						100% {
							transform: scale(1.025);
						}
					}

					@keyframes leaves {
						0% {
							transform: scale(1);
						}

						100% {
							transform: scale(1.025);
						}
					}
				}

				figcaption {
					.title {
						transform : translateX(-200%);
						transition: 1.3s all ease-in-out;
					}

					.description {
						transform       : translateX(-200%);
						transition      : 1.3s all ease-in-out;
						transition-delay: 1s;
					}
				}
			}

			&.swiper-slide-active {
				figure {
					img {
						transform: scale(1.1);
					}

					figcaption {

						.title,
						.description {
							transform: translateX(0);
						}

					}
				}
			}
		}

		.swiper-button-next {
			background-image   : url(../img/icons/banner-slider-next.svg);
			background-size    : 30px;
			background         : rgba(255, 255, 255, 0.3);
			background-position: 5px;
			background-repeat  : no-repeat;

			right        : -36px;
			width        : 73px;
			height       : 73px;
			z-index      : 1;
			border-radius: 50%;
			transition   : .3s all ease-in-out;

			@media (min-width: 1025px) {
				&:hover {
					border-radius: 50% 0 0 50%;
					right        : -20px;
				}
			}
		}

		.swiper-button-prev {
			background-image   : url(../img/icons/banner-slider-prev.svg);
			background-size    : 30px;
			background         : rgba(255, 255, 255, 0.3);
			background-position: 35px;
			background-repeat  : no-repeat;

			left         : -36px;
			width        : 73px;
			height       : 73px;
			z-index      : 1;
			border-radius: 50%;
			transition   : .3s all ease-in-out;

			@media (min-width: 1025px) {
				&:hover {
					border-radius: 0 50% 50% 0;
					left         : -20px;
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		.slider-HomeBanner {
			figure {
				position: relative;
				height  : auto;

				figcaption {
					left: 100px;
					top : 50px;
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.slider-HomeBanner {
			figure {
				figcaption {
					left: 40px;
					top : 30px;

					.title {
						font-size     : 20px;
						letter-spacing: 1px;
						margin-bottom : 10px;
					}

					.description {
						font-size: 12px;
					}
				}
			}
		}

		// SET HIỆU ỨNG CHO SLIDER
		.swiper-container {
			.swiper-button-next {
				background-size: 15px;
				right          : -25px;
				width          : 44px;
				height         : 44px;

			}

			.swiper-button-prev {
				background-size    : 15px;
				background-position: 25px;
				left               : -25px;
				width              : 44px;
				height             : 44px;
			}
		}
	}
}