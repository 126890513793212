.pagination {
	background     : #FFFFFF;
	display        : flex;
	justify-content: flex-end;
	padding        : 30px 0;

	@media (max-width: 1024.98px) {
		justify-content: center;
	}

	li {
		margin: 0 10px;

		@media (max-width: 1024.98px) {
			margin: 0 5px;
		}

		a {
			display        : flex;
			justify-content: center;
			align-items    : center;
			height         : 30px;
			width          : 30px;
			color          : #000000;
			border-radius  : 5px;
			font-weight    : 400;
			font-size      : 12px;
			background     : #dddddd;
			border         : 1px solid #FFFFFF;
			transition     : all .2s cubic-bezier(.2, .5, .5, .8);
		}

		&:hover {
			a {
				color     : #FFFFFF;
				background: #3E4095;
			}
		}

		&:last-child {
			margin: 0 0 0 10px;
		}

		&.active {
			a {
				color          : #FFFFFF;
				background     : #3E4095;
				text-decoration: underline;
			}
		}

		&.pagination-dot {
			a {
				border: 0;
			}
		}
	}
}

.lnr-chevron-left {
	&::before {
		content: '\e879'
	}
}

.lnr-chevron-right {
	&::before {
		content: '\e87a'
	}
}