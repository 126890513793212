.lien-he-truc-tiep {
	margin       : 60px 0 55px;
	padding-right: 40px;

	.main-title {
		font-size    : 34px;
		font-weight  : 700;
		margin-bottom: 65px;
		color        : #2B2B2B;
	}

	.list-contact {
		display  : flex;
		flex-wrap: wrap;
		margin   : 0 -6px;

		.item {
			flex         : 33.33333%;
			max-width    : 33.33333%;
			padding      : 0 6px;
			margin-bottom: 25px;

			figure {
				padding-bottom: 10px;
				border-bottom : 1px solid #F3F3F3;

				img {
					width: 100%;
				}

				figcaption {
					margin: 15px 0px 0px 5px;

					h3 {
						font-size  : 16px;
						font-weight: 700;
						color      : #3E4095;
					}

					span {
						display      : inline-block;
						font-size    : 12px;
						font-weight  : 300;
						color        : #2B2B2B;
						margin-bottom: 15px;
					}

					p {
						font-size: 14px;
						color    : #4D4D4D;
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin       : 30px 0;
		padding-right: 15px;

		.main-title {
			margin-bottom: 25px;
		}
	}

	@media (max-width: 767.98px) {
		.main-title {
			font-size: 24px;
		}

		.list-contact {
			.item {
				flex         : 100%;
				max-width    : 100%;
				margin-bottom: 20px;

				figure {

					figcaption {
						margin: 15px 0px 0px 5px;

						h3 {
							font-size: 18px;
						}

						span {
							font-size: 14px;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}