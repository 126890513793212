.introduce {
	margin-top: 45px;

	.title {
		color: #2B2B2B;

		span {
			font-size  : 24px;
			font-weight: 400;
		}

		h3 {
			font-size  : 34px;
			font-weight: 700;
		}
	}

	.description {
		margin-top: 35px;

		p {
			font-size    : 18px;
			color        : #4D4D4D;
			margin-bottom: 15px;

			&.bold {
				font-weight: 700;
				color      : #2B2B2B;
			}
		}
	}

	figure {
		margin-top: 60px;

		img {
			width: 100%;
		}
	}

	@media (max-width: 1024.98px) {
		margin-top: 30px;

		.title {
			span {
				font-size: 18px;
			}

			h3 {
				font-size: 24px;
			}
		}

		.description {
			margin-top: 15px;
		}

		figure {
			margin-top: 15px;
		}
	}

	@media (max-width: 767.98px) {
		.description {
			p {
				font-size: 16px;
			}
		}
	}
}

.sologan {
	margin-top: 78px;

	ul {
		list-style: none;
		padding   : 0;
	}

	.block-content {
		position  : relative;
		width     : 100%;
		height    : 140px;
		background: #FCFCFC;

		.content {
			position : absolute;
			width    : 100%;
			top      : 50%;
			transform: translateY(-50%);

			span {
				display      : block;
				text-align   : center;
				font-size    : 18px;
				color        : #2B2B2B;
				margin-bottom: 5px;
			}

			.list-item {
				display        : flex;
				justify-content: center;


				.item {
					margin-right: 25px;
					font-size   : 25px;
					color       : #2B2B2B;
					font-weight : 700;

					img {
						margin-bottom: 6px;
						margin-left  : 6px;
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin-top: 0;
	}

	@media (max-width: 767.98px) {
		display: none;
	}
}

.service-list {
	margin: 75px 0;

	ul {
		list-style: none;
		padding   : 0;
	}

	h4 {
		font-size    : 18px;
		font-weight  : 700;
		color        : #2B2B2B;
		margin-bottom: 20px;
	}

	.list-img {
		display  : flex;
		flex-wrap: wrap;
		margin   : 0 -7.5px;

		.item {
			flex         : 0 0 50%;
			max-width    : 50%;
			padding      : 0 7.5px;
			margin-bottom: 15px;
		}
	}

	.list-item-service {
		padding-left: 10px;

		.item {
			display      : flex;
			flex-wrap    : wrap;
			margin-bottom: 20px;

			h5 {
				flex     : 0 0 40%;
				max-width: 40%;

				font-size  : 18px;
				font-weight: 700;
				color      : #2B2B2B;
			}

			ul {
				flex     : 0 0 60%;
				max-width: 60%;

				li {
					font-size: 18px;
					color    : #707070;
				}
			}
		}
	}


	@media (max-width: 1024.98px) {
		margin: 15px 0;
	}

	@media (max-width: 767.98px) {
		h4 {
			font-size: 16px;
		}

		.list-item-service {
			padding-left: 0px;

			.item {
				margin-bottom: 15px;

				h5 {
					flex         : 0 0 100%;
					max-width    : 100%;
					margin-bottom: 15px;
					font-size    : 16px;
				}

				ul {
					flex     : 0 0 100%;
					max-width: 100%;

					li {
						font-size: 16px;
					}
				}
			}
		}
	}
}