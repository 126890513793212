.request {
	margin       : 60px 0 122px;
	padding-right: 40px;

	.form-row {
		margin: 0 -10px;
	}

	h3 {
		font-size    : 34px;
		color        : #2B2B2B;
		margin-bottom: 45px;
	}

	button {
		float: right;
	}

	.form-group {
		position     : relative;
		padding      : 0 10px;
		margin-bottom: 20px;

		&.small {
			padding: 0 5px;
		}

		input[type='text'] {
			width      : 100%;
			font-size  : 16px;
			color      : #707070;
			font-style : italic;
			line-height: 45px;
			padding    : 0 20px;
			border     : 1px solid #C1C1C1;
			font-style : italic;
		}

		input[type='number'] {
			width       : 100%;
			font-size   : 16px;
			color       : #707070;
			font-style  : italic;
			line-height : 45px;
			padding-left: 20px;
			border      : 1px solid #C1C1C1;
			font-style  : italic;
		}

		input[type='date'] {
			display   : block;
			color     : #707070;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			width     : 100%;
			height    : 45px;
			font-size : 16px;
			font-style: italic;
		}

		textarea.small {
			font-size: 16px;
			resize   : none;
			padding  : 15px;
			width    : 100%;
			height   : 90px;
			color    : #707070;
			border   : 1px solid #C1C1C1;
		}

		textarea.big {
			resize : none;
			padding: 15px;
			width  : 100%;
			height : 160px;
			color  : #707070;
			border : 1px solid #C1C1C1;
		}

		label {
			cursor       : pointer;
			width        : 100%;
			font-size    : 18px;
			color        : #2B2B2B;
			margin-bottom: 10px;

			&.color-red {
				color: #BC2E31;
			}

			&.block {
				color      : #FFFFFF;
				line-height: 45px;
				padding    : 0 15px;
				background : #3E4095;
			}
		}

		select {
			width     : 100%;
			background: none;
			height    : 45px;
			color     : #707070;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			font-style: italic;
		}

		.tableForm {
			.col-xl-6:nth-child(2) {
				@media (max-width: 1199.98px) {
					margin-top: 10px;
				}
			}

			&:nth-child(1) {
				td {
					&.tac-vu {
						.delete {
							display: none;
						}
					}
				}
			}

			+.tableForm {
				margin-top: 25px;

				td {
					&.tac-vu {
						.add {
							display: none;
						}
					}
				}
			}
		}

		table {
			border: 1px solid #C1C1C1;
			width : 100%;

			th,
			td {
				border: 1px solid #C1C1C1;
			}

			th {
				font-size : 16px;
				color     : white;
				background: #3e4095;
				text-align: center;
				height    : 50px;
				padding   : 10px;
				width     : 25%;
			}

			td {
				&.TypeContainer {
					width: 130px;
				}

				&.ContainerNo {
					width: 100px;
				}

				&.SealNo {
					width: 80px;
				}

				&.GW {
					width: 60px;
				}

				&.CBM {
					width: 60px;
				}

				&.NumberOfPackage {
					width: 105px;
				}

				&.Unit {
					width: 115px;
				}

				select,
				input {
					border: none !important;
				}

				&.tac-vu {
					width     : 81px;
					text-align: center;

					img {
						cursor : pointer;
						display: inline-block;
						margin : 0 5px;
					}
				}
			}
		}
	}

	.tableFormHidden {
		width     : 100%;
		margin-top: 20px;
	}

	@media (max-width: 1024.98px) {
		margin       : 30px 0;
		padding-right: 15px;

		h3 {
			margin-bottom: 15px;
		}

		.form-group {
			input[type='number'] {
				padding: 0 7px;
			}

			table {
				td {
					&.tac-vu {
						width: 100px;
					}
				}
			}
		}

		button {
			display: block;
			margin : 0 auto;
			float  : unset;
		}
	}

	@media (max-width: 767.98px) {
		.form-group {
			label {
				font-size: 16px;
			}
		}

		h3 {
			font-size: 24px;
		}
	}
}