.yeu-cau-bao-gia {
	margin       : 60px 0 135px;
	padding-right: 40px;

	.form-row {
		margin: 0 -10px;

		&.small {
			margin: 0 -5px;
		}
	}

	.title {
		h3 {
			font-size    : 34px;
			color        : #2B2B2B;
			margin-bottom: 20px;
		}
	}


	p {
		font-size    : 14px;
		color        : #2B2B2B;
		font-style   : italic;
		margin-bottom: 20px;
	}

	.yeu-cau-bao-gia-table {
		overflow-x   : auto;
		margin-bottom: 20px;

		h5 {
			font-size    : 18px;
			margin-bottom: 20px;
		}
	}

	.form-group {
		position     : relative;
		padding      : 0 10px;
		margin-bottom: 20px;

		&.small {
			padding: 0 5px;
		}

		h5 {
			font-size: 18px;
		}

		input[type='text'] {
			width      : 100%;
			font-size  : 16px;
			font-weight: 700;
			color      : #3E4095;
			font-style : italic;
			line-height: 45px;
			padding    : 0 40px 0 20px;
			border     : 1px solid #C1C1C1;
			font-style : italic;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		input[type='date'] {
			display   : block;
			color     : #3E4095;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			width     : 100%;
			height    : 45px;
			font-size : 16px;
			font-style: italic;
		}

		input[type='date']:not(.has-value)::before {
			content: attr(placeholder);
		}

		textarea.small {
			font-size  : 16px;
			resize     : none;
			padding    : 15px;
			width      : 100%;
			height     : 140px;
			color      : #3E4095;
			border     : 1px solid #C1C1C1;
			font-weight: 700;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		textarea.big {
			resize     : none;
			padding    : 15px;
			width      : 100%;
			height     : 215px;
			color      : #3E4095;
			border     : 1px solid #C1C1C1;
			font-weight: 700;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		label {
			cursor     : pointer;
			width      : 100%;
			color      : #707070;
			line-height: 45px;
			padding    : 0 15px;
			background : #f3f3f3;
		}

		select {
			cursor    : pointer;
			width     : 100%;
			background: none;
			height    : 45px;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			color     : #3E4095;
			font-style: italic;

			option {
				&:disabled {
					color: #707070;
				}
			}
		}

		.icon {
			position : absolute;
			right    : 35px;
			top      : 50%;
			transform: translateY(-50%);
		}

		.submit {
			position  : absolute;
			bottom    : 30px;
			right     : 30px;
			background: none;
			border    : none;

			span {
				font-size: 16px;
				color    : #2B2B2B;
			}
		}
	}

	table,
	thead,
	tbody,
	tr,
	td,
	th {
		border-collapse: collapse;
		border         : 1px solid #c1c1c1;


		input,
		textarea {
			border: 0;
			width : 100%;
		}

		&.remove {
			input {
				cursor    : pointer;
				background: #f3f3f3;
				color     : #707070;
			}
		}
	}

	table {
		width     : 100%;
		text-align: center;
		min-width : 745px;



		thead {
			tr {
				th {
					height    : 50px;
					border    : 1px solid #c1c1c1;
					background: #f3f3f3;
					color     : #707070;
				}
			}
		}

		tbody {
			tr {
				&.table-tong-cong {
					td {
						input {
							font-weight: 700;
						}

						&:first-child {
							input {
								text-align: center;
							}
						}
					}
				}
			}

			input[type='number'] {
				width: 100%;
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin       : 30px 0;
		padding-right: 15px;

		.col-lg-8 {
			padding-right: 15px;
		}

		.title {
			h3 {
				margin-bottom: 10px;
			}
		}
	}

	@media (max-width: 767.98px) {
		margin: 15px 0;

		.title {
			h3 {
				font-size    : 24px;
				margin-bottom: 5px;
			}
		}

		.form-group {
			margin-bottom: 10px;

			textarea.small {
				padding: 9px;
				height : 45px;
			}

			textarea.big {
				padding: 9px;
				height : 160px;
			}

			.submit {
				right : 15px;
				bottom: 15px;
			}
		}

	}
}

.table-responsive {
	display      : block;
	width        : 100%;
	max-width    : 100%;
	overflow-x   : auto;
	margin-bottom: 30px;
}