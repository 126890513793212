.homwe-news {
	margin-top: 125px;

	.title {
		margin    : 0 auto;
		width     : fit-content;
		text-align: center;

		h3 {
			line-height: 1;
			font-size  : 37px;
			color      : #2B2B2B;
		}

		>a {
			display      : inline-block;
			position     : relative;
			font-size    : 16px;
			font-style   : italic;
			color        : #707070;
			padding-right: 15px;

			&::after {
				content          : '';
				position         : absolute;
				top              : 50%;
				right            : 0;
				transform        : translateY(-50%);
				width            : 5px;
				height           : 10px;
				background-image : url(../img/icons/see-news.svg);
				background-size  : cover;
				background-repeat: no-repeat;
			}
		}
	}

	.list-item {
		margin-top: 60px;

		.item {
			margin-bottom: 55px;

			figure {
				display: flex;

				.box-img {
					position    : relative;
					overflow    : hidden;
					min-width   : 180px;
					height      : 150px;
					margin-right: 15px;

					img {
						transition: .5s all ease-in-out;
					}

					.icon {
						background-image : url(../img/icons/service-white.svg);
						width            : 30px;
						height           : 30px;
						background-repeat: no-repeat;

						position      : absolute;
						top           : 50%;
						left          : -100%;
						transform     : translate(-50%, -100%);
						z-index       : 1;
						opacity       : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;
					}

					&:after {
						content       : '';
						position      : absolute;
						opacity       : 0;
						width         : 100%;
						height        : 100%;
						top           : 0;
						left          : 0;
						pointer-events: none;
						transition    : .5s all ease-in-out;

						background: rgb(62, 64, 149);
						background: -moz-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						background: -webkit-linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						background: linear-gradient(180deg, rgba(62, 64, 149, 0.9) 0%, rgba(62, 64, 149, 0.19931722689075626) 100%, rgba(62, 64, 149, 0) 100%);
						filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#3E4095", endColorstr="#3E4095", GradientType=1);
					}
				}

				figcaption {
					color: #2B2B2B;

					.title-news {
						font-size         : 14px;
						height            : 42px;
						-webkit-line-clamp: 2;
						margin-bottom     : 3px;
					}

					.time {
						font-size     : 13px;
						letter-spacing: 4px;
						margin-bottom : 25px;
					}

					.description {
						font-size         : 14px;
						height            : 60px;
						-webkit-line-clamp: 3;
					}
				}
			}

			&:hover {
				figure {
					.box-img {
						img {
							transform: scale(1.1);
						}

						.icon {
							left          : 50%;
							transform     : translate(-50%, -50%);
							opacity       : 1;
							pointer-events: visible;
						}

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1024.98px) {
		margin-top: 50px;

		.title {
			h3 {
				font-size: 24px;
			}

			>a {
				font-size: 14px;
			}
		}

		.list-item {
			margin-top: 20px;

			.item {
				margin-bottom: 20px;

				figure {
					display       : block;
					padding-bottom: 20px;
					border-bottom : 1px solid #F3F3F3;

					.box-img {
						min-width    : 100%;
						height       : 280px;
						margin-bottom: 15px;
						margin-right : 0;
					}

					figcaption {
						color: #2B2B2B;

						.title-news {
							font-size         : 16px;
							height            : 42px;
							-webkit-line-clamp: 2;
							margin-bottom     : 3px;
						}

						.time {
							font-size    : 13px;
							margin-bottom: 15px;
						}

						.description {
							height            : 40px;
							-webkit-line-clamp: 2;
						}
					}
				}
			}
		}
	}
}