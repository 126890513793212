.list-service-home {
	.list-item {
		overflow: auto;
		width   : 100%;
	}

	.block-service {
		float: left;
		width: 50%;

		figure.big-img {
			height  : 500px;
			cursor  : pointer;
			position: relative;
			overflow: hidden;

			img {
				transition: .5s all ease-in-out;
			}

			figcaption {
				position: absolute;
				color   : #FFFFFF;
				top     : 50px;
				left    : 50px;
				z-index : 1;

				h5 {
					font-weight  : 700;
					font-size    : 37px;
					margin-bottom: 15px;
				}

				p {
					width        : 80%;
					padding-left : 25px;
					margin-bottom: 15px;
				}

				>a {
					padding-left: 25px;
				}
			}

			&::after {
				content   : '';
				position  : absolute;
				width     : 100%;
				height    : 100%;
				top       : 0;
				left      : 0;
				background: rgb(75, 75, 75);
				background: linear-gradient(180deg, rgba(75, 75, 75, 0.9) 0%, rgba(75, 75, 75, 0.19931722689075626) 100%, rgba(75, 75, 75, 0) 100%);
				filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="rgb(75, 75, 75)", endColorstr="rgb(75, 75, 75);", GradientType=1);
			}

			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}

		figure.small-img {
			height  : 250px;
			cursor  : pointer;
			overflow: hidden;

			display  : flex;
			flex-wrap: wrap;

			img {
				flex      : 0 0 50%;
				max-width : 50%;
				transition: .3s all ease-in-out;
			}

			figcaption {
				position : relative;
				flex     : 0 0 50%;
				max-width: 50%;

				color     : #FFFFFF;
				background: #2B2B2B;

				.box-content {
					position: absolute;
					top     : 30px;
					left    : 30px;
					width   : 88%;

					h5 {
						margin-bottom: 5px;
						font-size    : 25px;
						font-weight  : 400;
					}

					p {
						-webkit-line-clamp: 3;
						margin-bottom     : 20px;
						max-height        : 72px;
						font-size         : 16px;
					}

					>a {
						padding-left: 25px;
					}
				}
			}

			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}

		&:nth-child(3),
		&:nth-child(6) {
			figure {
				flex-direction: row-reverse;

				figcaption {
					background: #F3F3F3;
					color     : #2B2B2B;

					svg {
						g {
							stroke: #040404;
						}

						path {
							fill: #040404;
						}
					}
				}
			}
		}

		&:nth-child(5) {
			float: right;
		}
	}

	@media (max-width: 1024.98px) {
		.block-service {
			width: 100%;
		}
	}

	@media (max-width: 767.98px) {
		.block-service {
			figure.big-img {
				height: auto;

				figcaption {
					top : 25px;
					left: 20px;

					h5 {
						font-size    : 16px;
						margin-bottom: 15px;
					}

					p {
						padding-left: 10px;
						font-size   : 14px;
					}

					>a {
						padding-left: 10px;
					}
				}
			}

			figure.small-img {
				position: relative;
				height  : auto;

				img {
					height: 210px;
				}

				figcaption {
					.box-content {
						background: transparent;
						top       : 50%;
						left      : 50%;
						transform : translate(-50%, -50%);

						h5 {
							font-size    : 16px;
							margin-bottom: 15px;
						}

						p {
							padding-left: 0;
							font-size   : 14px;
						}

						>a {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}