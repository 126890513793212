.dealer-locator {

	// HÌNH LỚN
	figure.big-img {
		img {
			width: 100%;
		}

		figcaption {
			position  : absolute;
			color     : #FFFFFF;
			text-align: center;
			top       : 50%;
			left      : 50%;
			transform : translate(-50%, -50%);

			h3 {
				font-size    : 37px;
				font-weight  : 700;
				margin-bottom: 45px;
			}

			p {
				font-size: 22px;
			}
		}
	}

	// HÌNH NHỎ
	figure.small-img {
		cursor  : pointer;
		position: relative;
		height  : 380px;
		overflow: hidden;

		img {
			transition: .5s all ease-in-out;
		}

		figcaption {
			position  : absolute;
			width     : 100%;
			padding   : 0 50px;
			color     : #FFFFFF;
			bottom    : 45px;
			left      : 50%;
			z-index   : 1;
			transform : translateX(-50%);
			transition: .5s all ease-in-out;

			h3 {
				text-align : center;
				font-size  : 25px;
				font-weight: 700;
			}

			p {
				font-size: 16px;
				display  : none;
			}
		}

		&:after {
			content       : '';
			position      : absolute;
			opacity       : 0;
			width         : 100%;
			height        : 100%;
			top           : 0;
			left          : 0;
			pointer-events: none;
			transition    : .5s all ease-in-out;

			background: rgb(188, 46, 49);
			background: -moz-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
			background: -webkit-linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
			background: linear-gradient(180deg, rgba(188, 46, 49, 0.9) 0%, rgba(188, 46, 49, 0.19931722689075626) 100%, rgba(188, 46, 49, 0) 100%);
			filter    : progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc2e31", endColorstr="#bc2e31", GradientType=1);
		}

		&:hover {
			img {
				transform: scale(1.1);
			}

			figcaption {
				top      : 50%;
				left     : 50%;
				bottom   : unset;
				transform: translate(-50%, -50%);

				h3 {
					text-align: left;
				}

				p {
					display: block;
				}
			}

			&:after {
				opacity: 1;
			}
		}
	}

	@media (max-width: 1024.98px) {
		padding: 0 15px;

		// HÌNH LỚN
		figure.big-img {
			display: none;
		}

		// HÌNH NHỎ
		figure.small-img {
			height: auto;

			figcaption {
				position  : absolute;
				width     : 100%;
				padding   : 0 50px;
				color     : #FFFFFF;
				bottom    : 45px;
				left      : 50%;
				z-index   : 1;
				transform : translateX(-50%);
				transition: .5s all ease-in-out;

				h3 {
					text-align : center;
					font-size  : 25px;
					font-weight: 700;
				}

				p {
					font-size: 16px;
					display  : none;
				}
			}
		}
	}
}