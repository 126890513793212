.form-dangky-booking {
	margin       : 45px 0px 100px;
	padding-right: 40px;

	.form-row {
		margin: 0 -10px;

		&.mt-70 {
			margin-top: 70px;
		}
	}

	.title {
		margin-bottom: 50px;

		h3 {
			font-size  : 34px;
			font-weight: 700;
			color      : #2B2B2B;
		}
	}

	button {
		float: right;
	}

	.form-group {
		position     : relative;
		padding      : 0 10px;
		margin-bottom: 20px;

		&.small {
			padding: 0 5px;
		}

		input[type='text'] {
			width      : 100%;
			font-size  : 16px;
			color      : #3E4095;
			font-weight: 700;
			font-style : italic;
			line-height: 45px;
			padding    : 0 20px;
			border     : 1px solid #C1C1C1;
			font-style : italic;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		input[type='number'] {
			width       : 100%;
			font-size   : 16px;
			color       : #3E4095;
			font-weight : 700;
			font-style  : italic;
			line-height : 45px;
			padding-left: 20px;
			border      : 1px solid #C1C1C1;
			font-style  : italic;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		input[type='date'] {
			display   : block;
			color     : #3E4095;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			width     : 100%;
			height    : 45px;
			font-size : 16px;
			font-style: italic;
		}

		#buttonchoose {
			width        : 150px;
			line-height  : 30px;
			border-radius: 23px;
			border       : 1px solid #C1C1C1;
			text-align   : center;
			font-size    : 16px;
			color        : #707070;
			margin-top   : 10px;
		}

		textarea.small {
			font-size  : 16px;
			resize     : none;
			padding    : 15px;
			font-weight: 700;
			width      : 100%;
			height     : 90px;
			color      : #3E4095;
			border     : 1px solid #C1C1C1;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		textarea.big {
			resize     : none;
			padding    : 15px;
			width      : 100%;
			font-weight: 700;
			height     : 160px;
			color      : #3E4095;
			border     : 1px solid #C1C1C1;

			&::placeholder {
				color      : rgba($color: #707070, $alpha: .5);
				font-weight: 400;
			}
		}

		label {
			cursor       : pointer;
			width        : 100%;
			font-size    : 18px;
			color        : #2B2B2B;
			margin-bottom: 10px;

			&.color-red {
				color: #BC2E31;
			}

			&.block {
				color      : #FFFFFF;
				line-height: 45px;
				padding    : 0 15px;
				background : #3E4095;
			}
		}

		select {
			cursor    : pointer;
			width     : 100%;
			background: none;
			height    : 45px;
			color     : #3E4095;
			border    : 1px solid #C1C1C1;
			padding   : 10px 15px;
			font-style: italic;
		}
	}

	@media (max-width: 1024.98px) {
		margin       : 25px 0;
		padding-right: 15px;

		.form-row {
			&.mt-70 {
				margin-top: 0;
			}
		}

		.title {
			margin-bottom: 25px;

			h3 {
				font-size  : 34px;
				font-weight: 700;
				color      : #2B2B2B;
			}
		}

		button {
			display: block;
			float  : unset;
			margin : 0 auto;
		}
	}
}